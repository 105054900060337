'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var DonutChart = function () {

    // Sets up the options to be used througout the chart
    function DonutChart(options) {
        _classCallCheck(this, DonutChart);

        this.selector = options.selector;
        this.setWidths(options.selector.offsetWidth);
        this.data = options.data;
        //this.data is a POJO with the following properties:
        //goalMet; met; total; alwaysShowProgress; unit; action;
        this.angle = 360 * (1 / options.data.total);
    }

    // Establishes the widths used throughout the chart


    _createClass(DonutChart, [{
        key: 'setWidths',
        value: function setWidths(containerWidth) {
            // Give chart padding by making the diameter only 90% of the parent container
            this.diameter = containerWidth * 0.8;
            this.radius = this.diameter / 2;
            this.innerCircleRadius = this.radius * 0.65;
            this.innerCircleDiameter = this.innerCircleRadius * 2;
            this.mainTextSize = this.radius * 0.25;
            this.subTextSize = this.radius * 0.15;
        }

        // Initialize the chart

    }, {
        key: 'initialize',
        value: function initialize() {
            this.generate();
            this.resizeChart();
        }

        // Generate the chart

    }, {
        key: 'generate',
        value: function generate() {
            // Serialize XML SVG code into a string the browser can render
            var s = new XMLSerializer();
            this.selector.innerHTML = s.serializeToString(this.compileContent());

            // Determine if text needs to be wrapped or not - needed because SVG <text> does not wrap itself
            if (!this.data.goalMet) {
                this.formatText(this.selector.querySelector('.donutMainText'));
                this.formatText(this.selector.querySelector('.donutSubText'));
            }
        }

        // Re-sizes the chart when screen size is modified

    }, {
        key: 'resizeChart',
        value: function resizeChart() {
            var _this = this;

            window.addEventListener('resize', function () {
                // Set new values for widths based on new screen size
                _this.setWidths(_this.selector.offsetWidth);

                // Re-draw chart
                _this.generate();
            });
        }

        // Put the drawn vectors and inner content together

    }, {
        key: 'compileContent',
        value: function compileContent() {
            var outterSvg = this.drawVectors();
            var textFields = this.populateText();
            var completed = this.goalCompleted();

            if (this.data.goalMet) {
                outterSvg.appendChild(completed);
            } else {
                this.appendArrayItems(textFields, outterSvg);
            }

            return outterSvg;
        }

        // Prepare donut SVG with all circle and path elements

    }, {
        key: 'drawVectors',
        value: function drawVectors() {
            // The SVG that holds everything
            var svg = this.generateElement('svg', {
                'xmlns': 'http://www.w3.org/2000/svg',
                'width': '' + this.diameter,
                'height': '' + this.diameter
            });

            // The "empty" region of the chart
            var underCircle = this.generateElement('circle', {
                'r': '' + this.radius,
                'cx': '' + this.radius,
                'cy': '' + this.radius,
                'class': 'underCircle'
            });
            svg.appendChild(underCircle);

            // The "completed" region of the chart
            // If the total is over 100 or equal to 1, use a single path element to prevent slow loading
            if (this.data.total > 100 || this.data.total === 1 || (typeof $$ashChartOpts === 'undefined' ? 'undefined' : _typeof($$ashChartOpts)) === 'object' && _typeof($$ashChartOpts.donut) === 'object' && !$$ashChartOpts.donut.gap) {
                var completedSlice = this.filledPath();
                svg.appendChild(completedSlice);
            } else {
                var numberOfSlices = this.data.total >= this.data.met ? this.data.met : this.data.total;
                var slices = this.createSlices(numberOfSlices);
                this.appendArrayItems(slices, svg);
            }

            // The circle that allows the pie chart to appear as a donut
            var overCircle = this.generateElement('circle', {
                'r': '' + this.innerCircleRadius,
                'cx': '' + this.radius,
                'cy': '' + this.radius,
                'class': 'overCircle',
                'fill': '#fff'
            });
            svg.appendChild(overCircle);

            return svg;
        }

        // If slices are not used, this function draws the path that will fill the "completed" area of the chart

    }, {
        key: 'filledPath',
        value: function filledPath() {
            var radian = Math.PI / 180;
            var x1 = this.radius + this.radius * Math.cos(this.angle * this.data.met * radian);
            var x2 = this.radius + this.radius * Math.cos(this.angle * 0 * radian);
            var y1 = this.radius + this.radius * Math.sin(this.angle * this.data.met * radian);
            var y2 = this.radius + this.radius * Math.sin(this.angle * 0 * radian);
            var curve = this.data.met > this.data.total / 2 ? 1 : 0;
            var d = 'M' + this.radius + ' ' + this.radius + ' L' + x1 + ' ' + y1 + ' A' + this.radius + ' ' + this.radius + ' 0 ' + curve + ',0 ' + x2 + ' ' + y2 + ' z';

            var path = this.generateElement('path', {
                'd': '' + d,
                'transform': 'rotate(-90 ' + this.radius + ' ' + this.radius + ')',
                'class': 'completedSlice'
            });

            var completedCircle = this.generateElement('circle', {
                'r': '' + this.radius,
                'cx': '' + this.radius,
                'cy': '' + this.radius,
                'class': 'completedSlice'
            });

            if (this.data.goalMet && this.data.met / this.data.total === 1 || this.data.met >= this.data.total) {
                return completedCircle;
            }

            return path;
        }

        // Function that creates a filled "slice" for each "met" unit

    }, {
        key: 'createSlices',
        value: function createSlices(numberOfSlices) {
            var array = [];

            for (var i = 1; i <= numberOfSlices; i++) {
                var radian = Math.PI / 180;

                var x1 = this.radius + this.radius * Math.cos(this.angle * i * radian);
                var x2 = this.radius + this.radius * Math.cos(this.angle * (i - 1) * radian);
                var y1 = this.radius + this.radius * Math.sin(this.angle * i * radian);
                var y2 = this.radius + this.radius * Math.sin(this.angle * (i - 1) * radian);
                var d = 'M' + this.radius + ' ' + this.radius + ' L' + x1 + ' ' + y1 + ' A' + this.radius + ' ' + this.radius + ' 0 0,0 ' + x2 + ' ' + y2 + ' z';

                var path = this.generateElement('path', {
                    'd': '' + d,
                    'transform': 'rotate(-90 ' + this.radius + ' ' + this.radius + ')',
                    'class': 'completedSlice'
                });

                array.push(path);
            }

            return array;
        }
    }, {
        key: 'getMainText',
        value: function getMainText(data) {
            // Adds commas to larger numbers
            var addCommas = function addCommas(number) {
                // Needed because IE10 does not fully support ".toLocaleString()" (method that can also insert commas)
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            };
            var met = addCommas(this.data.met);
            var total = addCommas(this.data.total);

            if (data.unit === '%') {
                return this.containWordsInTspan([met + '%']);
            } else if (data.unit === '$') {
                return this.containWordsInTspan(['$' + met]);
            } else {
                if (!data.showMax) {
                    return this.containWordsInTspan(['' + met]);
                }
                return this.containWordsInTspan(['' + met, 'of', '' + total]);
            }
        }
    }, {
        key: 'getSubText',
        value: function getSubText(data) {
            if (!data.action) {
                return this.containWordsInTspan(['' + data.unit]);
            } else if (data.unit === '%' || data.unit === '$') {
                return this.containWordsInTspan(['' + data.action]);
            }

            return this.containWordsInTspan(['' + data.unit, '' + data.action]);
        }

        // Prepares the text that is contained within the center of the donut when not complete

    }, {
        key: 'populateText',
        value: function populateText() {
            // Gather content for the main text inside the chart
            var mainTextContent = this.getMainText(this.data);

            // Gather content for the sub text inside the chart
            var subTextContent = this.getSubText(this.data);

            var mainText = this.generateElement('text', {
                'x': '' + this.radius,
                'y': '' + this.radius * 0.9,
                'text-anchor': 'middle',
                'font-size': '' + this.mainTextSize,
                'class': 'donutMainText'
            });

            var subText = this.generateElement('text', {
                'x': '' + this.radius,
                'y': '' + this.radius * 1.2,
                'text-anchor': 'middle',
                'font-size': '' + this.subTextSize,
                'class': 'donutSubText',
                'style': 'visibility: hidden;'
            });

            mainTextContent.forEach(function (tspan) {
                mainText.appendChild(tspan);
            });
            subTextContent.forEach(function (tspan) {
                subText.appendChild(tspan);
            });

            return [mainText, subText];
        }

        // Split text into 2 separate <tspan> elements; this allows text wrapping if needed

    }, {
        key: 'containWordsInTspan',
        value: function containWordsInTspan(array) {
            var _this2 = this;

            var numberOfWords = array.length;

            if (numberOfWords > 1) {
                var _ret = function () {
                    var topRow = Math.ceil(numberOfWords / 2);
                    var bottomRow = Math.floor(numberOfWords / 2);

                    // The first <tspan>
                    var firstTspanText = array.filter(function (word, index) {
                        if (index < topRow) {
                            return word;
                        }
                    });
                    var firstTspan = _this2.generateElement('tspan', {
                        'text': '' + firstTspanText.join(' ')
                    });

                    // The second <tspan>
                    var secondTspanText = array.filter(function (word, index) {
                        if (index >= topRow) {
                            return word;
                        }
                    });
                    var secondTspan = _this2.generateElement('tspan', {
                        'text': ' ' + secondTspanText.join(' ')
                    });
                    // TODO: ^^^ Make one function that generates these <tspan>s?

                    return {
                        v: [firstTspan, secondTspan]
                    };
                }();

                if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
            } else {
                var tspan = this.generateElement('tspan', {
                    'text': '' + array.join()
                });

                return [tspan];
            }
        }

        // Wrap and reposition text if it's extra long

    }, {
        key: 'formatText',
        value: function formatText(textElement) {
            var maxWidth = this.innerCircleDiameter * 1.1;
            var elementWidth = textElement.getComputedTextLength();
            var fontSize = textElement.getAttribute('font-size');
            var tspanLength = textElement.getElementsByTagName('tspan').length;

            if (elementWidth > maxWidth && tspanLength > 1) {

                // Position main text upward a bit if it wraps to prevent overlapping of text below
                if (textElement.getAttribute('class') === 'donutMainText') {
                    textElement.setAttribute('y', '' + this.radius * 0.75);
                }

                // Adjust vertical and horizontal positioning of wrapped <tspan> tags
                var firstTspan = textElement.getElementsByTagName('tspan')[0];
                firstTspan.setAttribute('x', '' + this.radius);
                firstTspan.setAttribute('dy', '' + this.radius * 0.01);

                var secondTspan = textElement.getElementsByTagName('tspan')[1];
                secondTspan.setAttribute('x', '' + this.radius);
                secondTspan.setAttribute('dy', '' + fontSize);

                // Remove hidden style attribute
                textElement.removeAttribute('style');
            } else {
                // If text is not to be wrapped, just remove hidden style attribute
                textElement.removeAttribute('style');
            }
        }

        // Prepares content in the middle of the donut when complete

    }, {
        key: 'goalCompleted',
        value: function goalCompleted() {
            var mainGoalMetContent = this.getMainText(this.data);
            var subGoalMetContent = this.getSubText(this.data);
            var goalMetContainer = this.generateElement('g');
            var iconName = this.data.goalMetSvgName || 'goalMet';

            // Generate SVG element that holds the use element containing the call to get the goal met vector
            var goalMetVector = this.generateElement('svg', {
                'y': '' + this.radius * 0.4,
                'xmlns': 'http://www.w3.org/2000/svg'
            });

            // Generate the use tag with the goal met vector (can't use generateElement function with this)
            var goalMetIconUseTag = document.createElementNS('http://www.w3.org/2000/svg', 'use');
            goalMetIconUseTag.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + iconName + '-icon');
            goalMetVector.appendChild(goalMetIconUseTag);

            // Generate the text that appears below the goal met vector
            var goalMetTextTag = this.generateElement('text', {
                'x': '' + this.radius,
                'font-size': '' + this.subTextSize,
                'class': 'goalMetText',
                'text-anchor': 'middle'
            });
            var subTextTag = this.generateElement('text', {
                'x': '' + this.radius,
                'font-size': '' + this.subTextSize,
                'class': 'goalMetText',
                'text-anchor': 'middle'
            });

            // If more text other than 'Goal Met' is to be shown with trophy vector
            if (this.data.alwaysShowProgress) {
                mainGoalMetContent.forEach(function (text) {
                    goalMetTextTag.appendChild(text);
                });

                subGoalMetContent.forEach(function (text) {
                    subTextTag.appendChild(text);
                });

                goalMetTextTag.setAttribute('y', '' + this.radius * 1.3);
                subTextTag.setAttribute('y', '' + this.radius * 1.45);

                goalMetVector.setAttribute('height', '40%');
                goalMetVector.setAttribute('width', '40%');
                goalMetVector.setAttribute('x', '' + this.radius / 1.65);

                goalMetContainer.appendChild(goalMetVector);
                goalMetContainer.appendChild(goalMetTextTag);
                goalMetContainer.appendChild(subTextTag);
            } else {
                // Else, add 'Goal Met' text
                goalMetTextTag.setAttribute('y', '' + this.radius * 1.5);
                goalMetTextTag.textContent = 'Goal Met';

                goalMetVector.setAttribute('height', '50%');
                goalMetVector.setAttribute('width', '50%');
                goalMetVector.setAttribute('x', '' + this.radius / 2);

                goalMetContainer.appendChild(goalMetVector);
                goalMetContainer.appendChild(goalMetTextTag);
            }

            return goalMetContainer;
        }

        // Utility function that appends each item in an array to an element

    }, {
        key: 'appendArrayItems',
        value: function appendArrayItems(array, element) {
            array.forEach(function (item) {
                element.appendChild(item);
            });
        }

        // Utility function to generate SVG elements

    }, {
        key: 'generateElement',
        value: function generateElement(type, properties) {
            var element = document.createElementNS('http://www.w3.org/2000/svg', '' + type);

            for (var i in properties) {
                // Text props need to be assigned differently
                if (i === 'text') {
                    element.textContent = '' + properties[i];
                } else {
                    element.setAttribute('' + i, '' + properties[i]);
                }
            }

            return element;
        }
    }]);

    return DonutChart;
}();
