//ash functions partial
var $$ash = {
	//descriptions of custom global objects
	//ajaxFail = display failure message to the user when there is a server error with the ajax request
	//mq = global variables for media queries
	//formSuccess = display success message to the user when a form is submitted successfully
	//browserHist = a collection of browser history API functions
	//userList = a collection of functions that can be applied to the user-list, such as removeUser, removeItem, and tempErr (tempErr is currently not being used)
	//initSelect2 = if not on a touch device and the select2 prototype constructor exists, then run select2 on all select elements on the page
	//templateInitHelper = binds the template rendering to an event (click, dropdown change, etc) and sets the triggers
	//handlebarsInit = initialize handlebars by running $$ash.getHandlebarsTemplateAndJson with a $$ash.renderTemplate callback
	//getTemplateJson = make an ajax call to retrieve the json data only
	//getHandlebarsTemplate = make an ajax call to retrieve the handlebars template only
	//getTemplateData = use json object if it is provided, or make an ajax call to retrieve json data, then make an ajax call to retrieve the template and render it on the page
	//getHandlebarsTemplateAndJson = make ajax calls to retrieve the handlebars template and json data when both are missing
	//renderTemplate = insert already compiled handlebars template into target container
	//insertTemplateViaDropdown = insert portion of already compiled handlebars template into target container on dropdown change
	//insertTemplateViaButton = insert portion of already compiled handlebars template into target container on button click
	//validate = add validation classes and markup to object
	//clearValidation = remove validation classes and markup from object
	//openModal = set up modal windows using the ashModal plugin that will be triggered by a click on a .createModal link
	//renderModal = pass object to $$ash.runModal function
	//runModal = triggers the event handler to open the modal
	//getCallback = convert a dot notated function string into an object to be returned and called
	//formatNum = format number with comma and decimal if one exists
	//updateProgress = updates .user-progress progress bars
	//unstringJSON = parses 'data' into JSON if 'data' is a string
	//getTypeKit = create typekit script link, configure it, and add it to the head tag before any other script tags
	//cardsEqualHeight = when flexbox is not supported, run equalheights plugin on cards (only used in challenges as of 3/31/2015)
	//setDefaultOption = set the default option of the select element with the placeholder text
	//flexheightFallback = when flexbox is not supported, run equalheights plugin on all .flex-content items under a .flexheight parent
	//removeButtonOnSubmit = remove form submit button on form submit to prevent multiple clicks
	//serializeDataToObject = serialize form data and store in an object to be passed along in a form submit
	//reloadOnTimeout = if ajax request times out and returns a 401 status code, reload the page
	//ajax = submit ajax request with the default method GET
	//connectedWidgetInit = initialize the Connected! activity widget on the HYR dashboard
	//ashModalPrinter = opens a new browser window and formats the content to be printed
	//addLoader = add animated loader graphic while content is updated
	//removeLoader = remove the animated loader graphic
	//preventDefaultFormSubmit = prevent default form submit action from .ajaxPost forms
	//metaNavInit = initialize click functionality to show/hide user info in the off-canvas navigation on mobile screen sizes
	//viewMore = function run on click of 'view more' buttons to display addition list content
	//chatterPost = post new challenge chatter message to chatter feed on success of json retrieval, or display error message
	//prepopulateDate = set end date of a matched date input
	//convertDate = convert any date to ISO format. Also accepts parameters to change the month, day, and year of returned date
	//setHiddenInput = set the hidden input via target id attribute
	//formatter = general formatter that can accept different types (e.g., phone, date, etc) and spit out formatted version of the string
	//scroller =
	//storage.local.set = set a localStorage key/value pair
	//storage.local.get = get a localStorage value from a key
	//storage.session.set = set a sessionStorage key/value pair
	//storage.session.get = get a sessionStorage value from a key
	//storeValues = collect name/value pairs to store from specific data attributes and store them in specified storage
	//displayValues = get stored values from local or session storage and write them to elements on page
	//populateFields = populate a set of fields from json object, also has ability to disable the fields that are populated
	//scrollThrottle = throttles firing of scroll events
	//removeSelectEmptyOption = remove empty option tags on selects if the select's placeholder exists and is not only whitespace
	//initializeDonutChart = set up the donut chart by running ajax call to get the data for the chart
	//openModalFromTemplate = opens a modal from within a Handlebars template; modal content also comes from a separate handlebars template - allows for dynamic modal containers that update depending on what data is passed in
	//enableOnCheck = enables form submit button only when set conditions are met

	// if old version, arg1 = target, arg2 = errorMessage(s), arg3 = xhr object, arg4 = errorMessage position
	ajaxFail: function (arg1, arg2, arg3, arg4) {
		// to maintain backwards compatability, ajaxFail will use duck-typing to determine whether to use the old version of
		// ash.ajax or the new one.
		var useOldVersion = (function useOldVersionTest() {
			if (arg1 == null || arguments.length > 1 || arg1 instanceof jQuery || arg1 instanceof HTMLElement || typeof arg1 === 'string') {
				return true;
			}

			return false;
		}());

		var target;
		var errorMessage;
		var xhr;
		var position;

		function removeErrorsOnPage() {
			var errors = $u('.error'); // errors already on the page

			errors.forEach(function (obj) {
				obj.parentNode.removeChild(obj);

				TweenLite.killTweensOf(obj);
			});
		}

		function determineTarget(obj) {
			var target;

			function useDefaultTarget() {
				if ($u('.main-content').length) {
					return $u('.main-content')[0];
				}

				//else
				return document.body;
			}

			try {
				if (obj) {
					if (jQuery && obj instanceof jQuery && obj.length) {
						target = obj[0];
					} else if (typeof obj === 'string') {
						target = document.querySelector(obj) || useDefaultTarget();
					} else if (obj instanceof HTMLElement && document.body.contains(obj)) {
						target = obj;
					} else {
						target = useDefaultTarget();
					}
				} else {
					target = useDefaultTarget();

					position = 'prepend'; //don't like this here but it works.
				}
			} catch (e) {
				target = useDefaultTarget();
			}

			return target;
		}

		function createErrorMessage(message) {
			var output = document.createElement('div');
			var errorMessage;

			if (!message) {
				errorMessage = document.createElement('span');
				errorMessage.textContent = 'This request could not be completed. Please try again or contact us.';
			} else {
				if (message instanceof Array) {
					if (useOldVersion) {
						errorMessage = document.createElement('span');
						errorMessage.textContent = message[0];
					} else {
						errorMessage = document.createElement('ul');

						message.forEach(function (obj) {
							var listItem = document.createElement('li');

							listItem.textContent = obj.message;
							errorMessage.appendChild(listItem);
						});
					}
				} else {
					errorMessage = document.createElement('span');
					errorMessage.innerHTML = message;
				}
			}

			output.classList.add('error');
			output.appendChild(errorMessage);

			return output;
		}

		function insertErrorMessage(target, messageMarkup, position) {
			position = position || 'before';

			switch (position) {  //position loader in relation to select obj
				case 'replace':
					target.innerHTML = '';
					target.appendChild(messageMarkup);

					break;
				case 'prepend':
					target.insertBefore(messageMarkup, target.firstChild);
					break;
				case 'append':
					target.appendChild(messageMarkup);
					break;
				case 'before':
					target.parentNode.insertBefore(messageMarkup, target);
					break;
				case 'after':
					target.parentNode.insertBefore(messageMarkup, target.nextSibling);
					break;
				default:
					target.parentNode.insertBefore(messageMarkup, target);
			}

			TweenLite
				.fromTo(messageMarkup, 0.75, {
					css: { height: '0', padding: '0', opacity: '0' }
				}, {
					css: { height: 'auto', padding: '10px 32px', opacity: '1' }
				});
		}

		function reloadIfExpired(xhr) {
			var status;

			if (xhr) {
				status = xhr.status;

				if (status === 302 || status === 401) {
					window.location.reload();
				}
			}
		}

		(function init() {
			var errorObj;
			var errorTarget;
			var errorMessageDomElement;

			removeErrorsOnPage();

			if (useOldVersion) {
				target = arg1;
				errorMessage = arg2;
				xhr = arg3;
				position = arg4;
			} else {
				target = arg1.target;
				xhr = arg1.response;
				position = arg1.position;

				try {
					errorObj = arg1.response.responseJSON.responseStatus;
				} catch (e) {
					errorObj = {};
				}

				errorMessage = (function determineErrorMessage() {
					var customMessage = arg1.customMessage;
					var errorsArray = errorObj.errors;

					if (customMessage) {
						return customMessage
					}

					if (errorsArray instanceof Array && errorsArray.length) {
						return errorsArray;
					}

					return errorObj.message;
				}());
			}

			reloadIfExpired(xhr);

			errorTarget = determineTarget(target);
			errorMessageDomElement = createErrorMessage(errorMessage);

			insertErrorMessage(errorTarget, errorMessageDomElement, position);
		}());
	},
	//Usage example: $$ash.mq('sm', 'min')
	mq: function (num, exp) {
		var size,
			def = false;
		switch (num) {
			case 'xxs':
				num = 320;
				break;
			case 'xs':
				num = 500;
				break;
			case 'sm':
				num = 767;
				break;
			case 'md':
				num = 992;
				break;
			case 'lg':
				num = 1200;
				break;
			default:
				num = num; //allows usage of manually entered pixel width in the call, for 'abnormal' widths
				def = true;
		}
		switch (exp) {
			case 'min':
				exp = 'min-width';
				num = def ? num : num + 1; //adds 1 pixel if the expression is min-width and size is not entered manually
				break;
			default:
				exp = 'max-width';
		}
		size = '(' + exp + ': ' + num + 'px' + ')';
		return size;
	},

	formSuccess: function (obj, response) {
		response = $$ash.unstringJSON(response);
		//use "resetForm" class to trigger this function
		if (response.error === true) {
			$$ash.ajaxFail(obj, response.errorMessage);
		} else {
			var cont = obj.parent(),
				messageMarkup = $('<div/>', { //create a div with class message to put the message into
					'class': 'status-message success',
					'style': 'display: none'
				});
			messageMarkup.html('<p>' + response.message + '</p>');
			obj.siblings('.status-message.success').remove().end().before(messageMarkup);

			obj[0].reset();
			obj.find('select').select2('data', null); //reset select2

			messageMarkup.slideDown();
		}
	},
	history: {
		replaceState: function (data, title, url) {  //save page state
			if (Modernizr.history) {  //if html5 history api supported
				history.replaceState(data, title, url);
			}
		},
		pushState: function (data, title, url) {  //save page state into queue
			if (Modernizr.history) {  //if html5 history api supported
				history.pushState(data, title, url);
			}
		}
	},
	browserHist: {
		save: function (histArr, title, url) {  //save page state into queue
			if (Modernizr.history) {  //if html5 history api supported
				history.pushState(histArr, title, url);
			}
		},
		pop: function (ele) {  //listener to return page stat from queue
			if (Modernizr.history) { //if html5 history api supported
				window.addEventListener('popstate', function (e) {
					ele.trigger('rtnData', { data: e });
				});
			}
		}
	},
	userList: {
		removeUser: function (target, contClass) {
			var ele = target.closest('li'),
				cont = ele.closest(contClass),
				userId = target.attr('data-user-id');
			$.ajax({
				type: "POST",
				data: { user: userId },
				url: $(cont).data('ash-url'),
				success: function (response, textStatus, xhr) {
					$$ash.reloadOnTimeout(xhr);

					if (response.error) {
						$$ash.ajaxFail(ele.parent(), response.errorMessage);
					} else {
						$$ash.userList.removeItem(ele, response);
					}
				},
				error: function (xhr) {
					$$ash.ajaxFail(ele.parent(), undefined, xhr);
				}
			});
		},
		removeItem: function (ele, r) {
			var thisHeight = ele.outerHeight();
			ele.addClass('ajax-success').css('height', thisHeight + 'px');
			ele.find('div').fadeOut({
				duration: 500,
				complete: function () {
					ele.html('<div class="success-message"><span>' + r + '</span></div>');
				}
			});
		},
		tempErr: function (ele) {
			ele.text('error');
			ele.addClass('error');
		}
	},
	//init select 2 when not init with document ready
	initSelect2: function () {
		//if not a touch device, run select2
		if (!Modernizr.touchevents && $.fn.select2) {
			$('select').select2({
				minimumResultsForSearch: -1,
				formatNoMatches: function (term) {
					return 'Loading...';
				}
			});
		}
	},
	//TEMPLATE RENDERING  ***this function is outdated if possible avoid using in future***
	//To initiate, add the 'template-render-init' class to the <select>, <button>, or button-like element you wish to use as a "trigger"
	//The "trigger" then initiates the 'getTemplateData' function that will GET the json and js-template file
	//Be sure to have all the necessary data-attributes: 'data-json-url', 'data-ash-template-url', and 'data-ash-target-id'
	templateInitHelper: function (callback, triggerOverride) {
		var init = $('.template-render-init').not('.select2-container'); //filtering out select2-created placeholder divs

		if (!init.length) { //if it doesn't exist
			init = triggerOverride; //optional: only if you want to use a different trigger class and callback
		}
		//each template-render-init
		init.each(function () {
			var obj = $(this),
				trigr = obj,
				eventListener,
				dataValue = obj.data('value');
			//if obj is select do listener on change else do on click
			if (obj.is('select')) {
				eventListener = 'change';
			} else {
				eventListener = 'click';
			}
			//check if select is change or click then initiate getTemplateDate to get JSON
			function setTrigger() {
				if (eventListener === 'change') {
					trigr = obj.find('option:selected');
				}
				else if (eventListener === 'click') {
					trigr.off('click'); //why?
				}
				//load data
				$$ash.getTemplateData(trigr, trigr.attr('data-json-url'), trigr.attr('data-ash-template-url'), trigr.attr('data-ash-target-id'), callback);
			}

			if (obj.is('div, ul')) { //if div or ul, then instantly trigger template load
				setTrigger();
			}

			else {
				if (obj.is('select')) {
					eventListener = 'change';
				} else {
					eventListener = 'click';
				}

				if (dataValue) { //auto-fill fields/dropdowns if dataValue exists
					obj.val(dataValue);
					setTrigger();
				}
				//take the trigger off then put it back on to make sure is only called once
				obj.off(eventListener + '.renderInit').on(eventListener + '.renderInit', setTrigger);
			}
		});
	},
	handlebarsInit: function (obj, options) { //cleaner version of templateRenderInit. Doesn't have as many functions, however.
		var opts = options || {},
			target = opts.target || obj.attr('data-ash-target-id');

		$$ash.getHandlebarsTemplateAndJson(obj, {
			templateUrl: opts.templateUrl || obj.attr('data-ash-template-url'),
			jsonUrl: opts.jsonUrl || obj.attr('data-json-url'),
			jsonData: $$ash.unstringJSON(opts.jsonData),
			callback: function (data) {
				if (opts.useNewVersion !== true) {
					$$ash.renderTemplate(obj, data.template, data.json, target, opts.callback, {
						insertType: opts.insertType || obj.attr('data-insert-type')
					});
				} else {
					$$ash.renderTemplate2({
						trigger: obj,
						template: data.template,
						json: data.json,
						target: target,
						callback: opts.callback,
						insertType: opts.insertType,
						numberToLoad: opts.numberToLoad,
						keyToSlice: opts.keyToSlice,
						sliceStartIndex: opts.sliceStartIndex
					});
				}
			}
		});
	},
	getTemplateJson: function (obj, jsonUrl) { //function that returns the JSON only
		jsonUrl = jsonUrl || obj.attr('data-json-url');

		if (typeof jsonUrl === 'undefined') {
			throw ('error: JSON URL is not configured correctly. Please ensure you are either passing in a URL or that the HTML element has an attribute "data-json-url" with a valid path');
		} else {
			return $$ash.ajax({
				url: jsonUrl,
				callback: function (response, textStatus, xhr) {
					$$ash.reloadOnTimeout(xhr);

					if (response.error) {
						$$ash.ajaxFail(obj.parent(), jsonData.errorMessage);
					}
				},
				error: function (xhr) {
					$$ash.ajaxFail(obj, undefined, xhr);
				}
			});
		}
	},
	getHandlebarsTemplate: function (obj, templateUrl) {
		templateUrl = templateUrl || obj.attr('data-ash-template-url');

		if (typeof templateUrl === 'undefined') {
			throw ('error: Template URL is not configured correctly. Please ensure you are either passing in a URL or that the HTML element has an attribute "data-ash-template-url" with a valid path');
		} else {
			return $$ash.ajax({
				url: templateUrl,
				callback: function (response, textStatus, xhr) {
					$$ash.reloadOnTimeout(xhr);
				},
				error: function (xhr) {
					$$ash.ajaxFail(obj, undefined, xhr);
				}
			});
		}
	},
	//NOTE: jsonUrl can be a json object OR a path to a json formatted file
	getTemplateData: function (trigr, jsonUrl, templateUrl, targetId, callback) {
		//trigr = the element with a bound event handler to trigger this function
		//targetId = the ID of the element whose content will either be replaced or appended to
		//callback = callback function to be passed to the $$ash.renderTemplate object upon success
		//the element to append or replace the content of with the new rendered template
		var target = $(document.getElementById(targetId)),
			//variable container to hold new html from ajax retrieved data
			templateHtml,
			//empty jsonData object
			jsonData = {};
		//if no jsonUrl path is passed to the function, get it from the trigger element's data attribute
		jsonUrl = jsonUrl || trigr.attr('data-json-url');
		//if no templateUrl path is passed to the function, get it from the trigger element's data attribute
		templateUrl = templateUrl || trigr.attr('data-ash-template-url');

		//display animated loader
		$$ash.addLoader(target, 'append', 'center');

		//if missing json and template, kill function
		//this will prevent issue where page reloads and freezes
		//this should be a rare case caused by bad markup
		if (!jsonUrl && !templateUrl) {
			return false;
		}

		//if no jsonUrl or if function was passed a json object instead of a jsonUrl
		if (!jsonUrl || typeof jsonUrl === 'object') {
			jsonData = jsonUrl;

			$.when(
				//ajax request to get template data
				$.get(templateUrl, function (data, textStatus, xhr) {
					$$ash.reloadOnTimeout(xhr);

					templateHtml = data;
				})

			).then(
				//success
				function () {
					//update the page for the user and remove the animated loader
					$$ash.renderTemplate(trigr, templateHtml, jsonData, target, callback);
					$$ash.removeLoader();
				},
				//fail
				function (xhr) {
					//display fail message to user
					$$ash.ajaxFail(target.parent(), undefined, xhr);
					$$ash.removeLoader();
				}
			);
			//else if jsonUrl is passed to function
		} else {
			$.when(
				//ajax request to get json data
				$$ash.ajax({
					cache: false,
					url: jsonUrl,
					callback: function (data, textStatus, xhr) {
						$$ash.reloadOnTimeout(xhr);
					}
				}),
				//ajax request to get template data
				$.get(templateUrl, function (data, textStatus, xhr) {
					$$ash.reloadOnTimeout(xhr);
				})

			).then(
				//success
				function (jsonDataArr, templateHtmlArr) {
					jsonData = $$ash.unstringJSON(jsonDataArr[0]);
					templateHtml = templateHtmlArr[0];

					if (jsonData.error) {
						//if there is an error in the returned jsonData, display fail message to user
						$$ash.ajaxFail(target.parent(), jsonData.errorMessage);
					} else {
						//if jsonData has no errors, update the page for the user and remove the animated loader
						$$ash.renderTemplate(trigr, templateHtml, jsonData, target, callback);
						$$ash.removeLoader();
					}
				},
				//fail
				function (xhr) {
					//display fail message to user
					$$ash.ajaxFail(target.parent(), undefined, xhr);
					$$ash.removeLoader();
				}
			);
		}
	},
	getHandlebarsTemplateAndJson: function (obj, opts) {
		//templateUrl path
		var templateUrl = opts.templateUrl,
			//jsonUrl path
			jsonUrl = opts.jsonUrl,
			//json object
			jsonData = opts.jsonData,
			//callback function to be run after the new template renders
			callback = opts.callback,
			//empty handlebarsData object
			handlebarsData = {};

		$$ash.getHandlebarsTemplate(obj, templateUrl).done(function (response) {
			handlebarsData.template = response;

			//if json object exists, use it for handlebars data
			if (jsonData) {
				handlebarsData.json = jsonData;

				callback(handlebarsData);
				//else use json path for handlebars data
			} else {
				$$ash.getTemplateJson(obj, jsonUrl).done(function (response) {
					handlebarsData.json = response;

					callback(handlebarsData);
				});
			}
		});
	},

	//trigr the element to trigger i.e. (.view-results) -- Using wellness poll example --
	//templateHtml, the handlebars i.e. (poll-results.html)
	//jsonData i.e. (pollresponse.aspx)
	//target where items will be inserted i.e. data-ash-target-id="poll-list"
	//insertType: how the data will be inserted, prepended, appended or replaced html
	renderTemplate: function (trigr, templateHtml, jsonData, target, callback, opts) {
		var template = Handlebars.compile(templateHtml),
			numberToLoad = trigr.data('number-to-load'), //optional data-attribute. how many json items to load?
			insertType = (opts && opts.insertType) || trigr.attr('data-insert-type') || 'html', //optional: prepend or append? if not specified, then it will simply replace everything.
			fn,
			valInput = $('input, textarea, select');

		//if target === object, target = target else target = $(# target)
		target = (typeof target === 'object') ? target : $('#' + target); //if a function calls renderTemplate instead of getTemplate Data

		//if there is no target defined set target to the trigger
		if (!target.length) { //invalid target will replace trigr with the new content
			target = trigr;
		}

		//if input, textarea, select exist, clear validation
		if (valInput.length) {
			valInput.each(function () {
				if (!$(this).data("keep-validation")) $$ashVal.clearValidation($(this));
			});
		}

		//how the data will be inserted, prepended, appended or replaced w/ html
		switch (insertType) { //TODO: AO: This switch can probably be taken out
			case 'prepend':
				insertType = 'prepend';
				break;
			case 'append':
				insertType = 'append';
				break;
			default:
				insertType = 'html';
				break;
		}

		//numbertoLoad to decide how many items from the JSON to show at a time
		if (!numberToLoad) {
			//remove the animated loader
			$$ash.removeLoader();

			//prepend, append, or replace target content with compiled handlebars template  i.e. target.prepend(jsonData)
			target[insertType](template(jsonData));

			//if there is a select2 in the data loaded, it must be restyled and call select2 again.
			if (!Modernizr.touchevents && $.fn.select2) { //if not a touch device, run select2
				var select = $('select');
				if (select.hasClass('search')) {
					select.select2();
				} else if (select.not('.select2-offscreen')) {
					select.select2({
						minimumResultsForSearch: -1,
						formatNoMatches: function (term) {
							return 'Loading...';
						}
					});
				}
			} else {
				$$ash.removeSelectEmptyOption();
			}

			if (typeof callback === 'function') {
				callback(trigr, jsonData, target);
			}
		}
		//call dropdown function or button function depending on the trigger
		else {
			if (trigr.is('option')) {
				fn = $$ash.insertTemplateViaDropdown;
			} else {
				fn = $$ash.insertTemplateViaButton;
			}

			fn(trigr, template, jsonData, target, numberToLoad, insertType, callback);
		}

		if ($('form.validate').length) {
			$$ashVal.init();
		} //must run ashVal again in case there are new input fields to validate

		if ($('.user-progress').length) {
			$$ash.updateProgress();
		} //must run userProgress again in case progress values have changed

		//AO: Run this AFTER template insertion
		if (typeof bodyLinkProcessing !== "undefined") {
			var links = bodyLinkProcessing();
			bodyLinkRedir(links);
		}
	},
	renderTemplate2: function (opts) {
		var trigger = opts.trigger,
			json = opts.json,
			template = opts.template,
			numberToLoad = opts.numberToLoad || trigger.attr('data-number-to-load'),
			insertType = opts.insertType || trigger.attr('data-insert-type') || 'html',
			keyToSlice = opts.keyToSlice || trigger.attr('data-key-to-slice'),
			sliceStartIndex = opts.sliceStartIndex || 0,
			callback = opts.callback,
			originalArrayBeforeSlice,
			target,
			compiledTemplate;

		if (numberToLoad) {
			(function sliceData() {
				if (!keyToSlice) {
					throw new Error('If you specify a number to load, you must specify which key in the JSON to slice.');
				} else if (json[keyToSlice] instanceof Array === false) {
					throw new Error('keyToSlice must be a valid array in the supplied JSON');
				}

				originalArrayBeforeSlice = json[keyToSlice];
				json[keyToSlice] = json[keyToSlice].slice(sliceStartIndex, sliceStartIndex + numberToLoad);
			})();
		}

		target = opts.target instanceof jQuery ? opts.target : $('#' + opts.target);
		compiledTemplate = Handlebars.compile(template);

		//if there is no target defined set target to the trigger
		if (!target.length) { //invalid target will replace trigr with the new content
			target = trigger;
		}

		target[insertType](compiledTemplate(json));

		if (typeof callback === 'function') {
			if (numberToLoad) {
				json[keyToSlice] = originalArrayBeforeSlice;
			}

			callback({
				trigger: trigger,
				json: json,
				template: template,
				numberToLoad: numberToLoad,
				insertType: insertType,
				keyToSlice: keyToSlice,
				sliceStartIndex: sliceStartIndex,
				callback: callback,
				target: target
			});
		}
	},
	insertTemplateViaDropdown: function (trigr, template, jsonData, target, numberToLoad, insertType, callback) {
		//trigr = selected option of dropdown
		//template = compiled handlebars template html
		//jsonData = jsonData passed from renderTemplate
		//target = the element to prepend, append or replace the content of with the new rendered template
		//numberToLoad = number of json items to load
		//insertType = jQuery method to use to insert the new data ('prepend', 'append', 'html')
		//callback = callback function to be run after the new template renders
		//empty newJsonData object
		var newJsonData = {};

		for (var k in jsonData) {
			if (jsonData.hasOwnProperty(k)) {
				//if jsonData[k] is Array
				if (Object.prototype.toString.call(jsonData[k]) === '[object Array]') {
					newJsonData[k] = jsonData[k].slice(0, numberToLoad);
					//else if key value is only a string
				} else {
					newJsonData[k] = jsonData[k];
				}
			}
		}
		//remove the animated loader
		$$ash.removeLoader();

		//prepend, append, or replace target content with compiled handlebars template
		target[insertType](template(newJsonData));

		//prevent default form submit action from .ajaxPost forms
		$$ash.preventDefaultFormSubmit();

		//if callback passed is a typeof 'function', then run it
		if (typeof callback === "function") {
			callback(trigr, jsonData, target);
		}
	},
	insertTemplateViaButton: function (trigr, template, jsonData, target, numberToLoad, insertType, callback) {
		//trigr = the button element that triggers this function
		//template = compiled handlebars template html
		//jsonData = jsonData passed from renderTemplate
		//target = the element to prepend, append or replace the content of with the new rendered template
		//numberToLoad = number of json items to load
		//insertType = jQuery method to use to insert the new data ('prepend', 'append', 'html')
		//callback = callback function to be run after the new template renders
		//empty newJsonData object
		var newJsonData = {},
			//variable container to hold index of last slice
			newStartIndex;

		function insertData(startIndex) {
			//endSlice is the sum of the starting index and the number of json items to load
			var endSlice = startIndex + numberToLoad;

			for (var k in jsonData) {
				if (jsonData.hasOwnProperty(k)) {
					//if jsonData[k] is Array
					if (Object.prototype.toString.call(jsonData[k]) === '[object Array]') {
						newJsonData[k] = jsonData[k].slice(startIndex, endSlice);
						//else if key value is only a string
					} else {
						newJsonData[k] = jsonData[k];
					}
				}
			}

			//remove the animated loader
			$$ash.removeLoader();

			//prepend, append, or replace target content with compiled handlebars template
			target[insertType](template(newJsonData));

			//set the newStartIndex equal to the last index that was sliced in the array
			newStartIndex = endSlice;

			//prevent default form submit action from .ajaxPost forms
			$$ash.preventDefaultFormSubmit();

			//if callback passed is a typeof 'function', then run it
			if (typeof callback === "function") {
				callback(trigr, jsonData, target, endSlice);
			}
		}

		//call the insertData function and pass in a starting index of 0
		insertData(0);

		//when the trigr button is clicked, call the insertData function and pass in the newStartIndex
		trigr.click(function () {
			insertData(newStartIndex);
		});
	},
	validate: function (obj, str) {
		if (!obj.parent('.validation_container').length) {
			obj.addClass('invalidInput').wrap('<div class="inputField validation_container"></div>').after('<div class="validation_message">' + str + '</div>');
		}
	},
	clearValidation: function (obj) {
		obj.unwrap().removeClass('invalidInput').next().remove();
	},
	openModal: function () {
		$('.createModal').each(function () {
			var obj = $(this),
				modalMatch = $('.ashModalCont[data-ash-ashmodalmatch="' + obj.attr('data-ash-ashmodalmatch') + '"]'),
				modalCallback = obj.attr('data-ash-callback');
			if (modalCallback) {
				modalCallback = $$ash.getCallback(modalCallback); //pass data value through $$ash.getCallback function
			}
			obj.ashModal({
				'theContent': modalMatch,
				'callbackAfterClick': modalCallback,
				'closeContent': '<span class="icon-closeExit" aria-hidden="true"></span>'
			});
		});
	},
	renderModal: function () {
		$('.createModal').each(function () {
			var obj = $(this);
			if (obj.data('ash-display-modal')) {
				$$ash.runModal(obj, 'click');
			}
		});
	},
	runModal: function (obj, trg) {
		obj.trigger(trg); //trigger function to run a modal
	},
	getCallback: function (obj) {
		var callback = null;
		if (obj) {
			callback = window; //set callback variable to window object
			if (obj.indexOf('.') > -1) {
				var cbObj = obj.split('.');
				for (var i = 0; i < cbObj.length; i++) {
					callback = callback[cbObj[i]]; //add object nodes to window object
				}
				return callback;
			} else {
				callback = callback[obj];
				return callback;
			}
		} else {
			return callback;
		}
	},
	formatNum: function (obj) {
		obj += '';
		var x = obj.split('.'),
			x1 = x[0],
			x2 = x.length > 1 ? '.' + x[1] : '',
			regEx = /(\d+)(\d{3})/;
		while (regEx.test(x1)) {
			x1 = x1.replace(regEx, '$1' + ',' + '$2');
		}
		return x1 + x2;
	},
	updateProgress: function () {
		$('.user-progress').each(function () {
			var obj = $(this),
				progress = Number(obj.find('.progress-current').text().replace(',', '')), //remove comma if number includes one
				goal = Number(obj.find('.progress-max').text().replace(',', '')), //remove comma if number includes one
				percent;
			if (progress > goal) { //if progress exceeds goal, stop progress bar from growing
				progress = goal;
			}
			percent = Math.floor((progress / goal) * 100) + '%';
			obj.find('.progress-current').width(percent);
			obj.attr('data-ash-percent', percent); //add percent in data tag (used when % is displayed)
		});
	},
	unstringJSON: function (data) {
		var output;

		if (typeof data === 'string' && data.length > 0) {
			try {
				output = $.parseJSON(data);
			} catch (e) {
				output = data;
			}
		} else {
			output = data;
		}

		return output;
	},
	//async call to typekit to prevent scripts from blocking the page. this may result in FOUT but will prevent a bigger issue of an
	//entire page of plain white waiting for scripts to load, especially if typekit is blocked on the users machine
	getTypeKit: function (typeKitId) {
		var typekitConfig = {
			kitId: typeKitId //declared in site-specific (ie: sf.js) JS file
		};
		var d = false;
		var tk = document.createElement('script');
		tk.src = '//use.typekit.net/' + typekitConfig.kitId + '.js';
		tk.type = 'text/javascript';
		tk.async = 'true';
		tk.onload = tk.onreadystatechange = function () {
			var rs = this.readyState;
			if (d || rs && rs != 'complete' && rs != 'loaded') return;
			d = true;
			try { Typekit.load(typekitConfig); } catch (e) { }
		};
		var s = document.getElementsByTagName('script')[0];
		s.parentNode.insertBefore(tk, s);
	},
	cardsEqualHeight: function () {
		//ACTIVE EQUALHEIGHTS PLUGIN
		//cards length > 1 because EH only needed if more than one card
		if ($('html.no-flexbox ul.cards').length) {
			var carLIs = $('html.no-flexbox .cards li .card-element'),
				winWth = $(window).width();

			carLIs.removeAttr('style');
			if (winWth > 750 && carLIs.length > 1) {
				carLIs.equalHeights({ 'overflow': 'visible' });
			}
		}
	},

	flexheightFallback: function () {
		$('html.no-flexbox .flexheight').each(function () {
			var obj = $(this);

			obj.find('.flex-content')
				.css('height', 'auto')
				.equalHeights({
					'overflow': 'visible'
				});
		});
	},
	removeButtonOnSubmit: function (form) {
		var obj = form;
		var btn = obj.find(':submit');
		var isNextBtn = btn.hasClass('next');
		var isCentered = btn.attr('data-centerLoader') ? true : false;
		var ignoreBtn = btn.attr('data-noLoader') ? true
			: btn.parents().hasClass('findFacility') ? true
				: btn.parents().find('#fsForm').length > 0 ? true
					: false;
		var align;
		var loaderClass = Modernizr.inlinesvg ? 'svg-loader' : 'loader';

		function resetForm(btn, loader) {
			$$ash.removeLoader(loader);
			btn.fadeIn();
		}
		if (!ignoreBtn) {
			if (isNextBtn === true && Modernizr.mq('screen and (min-width: 501px)')) {
				align = 'next';
			} else if (isCentered || Modernizr.mq('screen and (max-width: 500px)')) {
				align = 'center'
			}


			btn.hide();
			$$ash.addLoader(btn, 'before', align, loaderClass);

			obj.on('validationFail ajaxPostComplete', function () {
				resetForm(btn, loaderClass);
			});
		}
	},
	serializeDataToObject: function (form) {
		var ajaxData = Array.prototype.slice.call(form[0].querySelectorAll('input,select,textarea'))
			.filter(function (control) { return control.type !== 'submit' })
			//Ensure that we have a name attr for radio groups
			.filter(function (control) {
				if ((control.type === 'radio') && !control.name) throw 'Every radio group requires a "name" attribute!';
				return true;
			})
			//Get rid of inputs that don't need to go to server
			.filter(function (control) { return !control.classList.contains('noServerVal'); })
			//Remove date picker and select2 fallbacks
			.filter(function (control) {
				return !(control.classList.contains('date-picker') || control.classList.contains('select2-offscreen'));
			})
			//Remove unselected radio options
			.filter(function (control) {
				return !(control.type === 'radio' && !control.checked);
			})
			//Transform any input values that need it
			.map(function (control) {
				control.ajaxValue = (control.type === 'checkbox') ? control.checked : control.value;
				return control;
			})
			.reduce(function (acc, control) {
				var objKey = (control.type === 'radio' || control.type === 'hidden') ? control.name : control.id;
				acc[objKey] = control.ajaxValue;
				return acc;
			}, {});
		return ajaxData;
	},
	reloadOnTimeout: function (xhr) {
		var responseHeaderJson = xhr ? $$ash.unstringJSON(xhr.getResponseHeader('X-Responded-JSON')) : null;

		if (responseHeaderJson) {

			if (responseHeaderJson.status === 401) {
				location.reload();
			}
		}
	},
	ajax: function (o) {
		var ajaxOpts = {};
		var obj = o.obj;
		var url = o.url;
		var type = o.method || 'GET';
		var async = o.async;
		var data = o.data;
		var cache = o.cache === false ? false : true;
		var headers = o.headers || {};
		var contentType = o.contentType === false ? false : (o.contentType || 'application/x-www-form-urlencoded; charset=UTF-8');
		var dataType = o.dataType;
		var processData = o.processData === false ? false : true;
		var beforeSend = o.beforeSend;
		var callback = o.callback;
		var callback2 = o.callback2;
		var cbOpts = o.cbOpts;
		var error = o.error; //function you want to run if there's an erro
		var complete = o.complete;
		var always = o.always;
		var dfd = o.dfd;
		var failMessage = o.failMessage;
		var failMessageLocation = o.failMessageLocation;
		var failMessagePos = o.failMessagePos;
		var failOpts;
		var failTypeNew = o.failTypeNew || false;
		var submitButton;
		var disableSubmitWhilePostingOpts = o.disableSubmitWhilePostingOpts; //Pass in an object w/ options if you want to ;disablesubmit button while POSTing
		var suppressSuccessTrigger = o.suppressSuccessTrigger || false; //flag that determines whether a success triggers $$ash:ajaxSuccess on $(document)
		var loaderOpts = o.loaderOpts;
		var loaderLocation = loaderOpts && loaderOpts.location;
		var loaderPosition = loaderOpts && loaderOpts.position || 'after';
		var loaderAlign = loaderOpts && loaderOpts.align || 'center';
		var traditional = o.traditional;
		var xLocation;
		//Array of HTTP error codes for which the default server error UI should not be displayed.
		var ajaxIgnoreErrorDisplayCodes = o.ajaxIgnoreErrorDisplayCodes || [];

		if (loaderOpts && !loaderLocation) {
			throw new Error('You must specify where to insert the loader');
		} else {
			ajaxOpts = {
				url: url,
				type: type,
				data: data,
				cache: cache,
				async: async,
				contentType: contentType,
				processData: processData,
				headers: headers,
				xhrFields: {
					withCredentials: $$ash.enableAjaxCredentials === true ? true : false
				},
				beforeSend: function () {
					var validationError;

					if (obj && obj.hasClass('validate')) {
						validationError = $$ashVal.validateAll(obj);

						if (validationError === true) {
							return false;
						}
					}
					if (disableSubmitWhilePostingOpts) {
						(function disableButtonWhilePosting() {
							var o = disableSubmitWhilePostingOpts;

							submitButton = o.submitButton;

							if (!submitButton) {
								if (obj.is('form')) {
									submitButton = obj.find('input[type="submit"]');
								} else {
									throw new Error('Can\'t find an input type=submit to disable.');
								}
							}

							obj.disableButtonDuringAjaxPost({
								submitButton: submitButton,
								loadingText: o.loadingText || 'Loading...'
							});
						})();
					}
					if (loaderOpts) {
						$$ash.addLoader(loaderLocation, loaderPosition, loaderAlign);
					}
					if (typeof beforeSend === 'function') {
						beforeSend();
					}
				},
				error: function (xhr) {
					if (ajaxIgnoreErrorDisplayCodes.indexOf(xhr.status) === -1) {
						if (failTypeNew) {
							failOpts = {
								target: failMessageLocation,
								response: xhr,
								position: failMessagePos
							}
							$$ash.ajaxFail(failOpts);
						} else {
							$$ash.ajaxFail(failMessageLocation, failMessage, xhr, failMessagePos);
						}
					}

					//if passing a deferred object, reject it
					if (dfd) {
						dfd.reject();
					}

					if (typeof error === 'function') {
						error(xhr);
					}
				}, //function you want to run if there's an error
				success: function (response, textStatus, xhr) {
					$$ash.reloadOnTimeout(xhr);
					response = $$ash.unstringJSON(response);

					if (response && response.error) {
						$$ash.ajaxFail(failMessageLocation, response.errorMessage, null, failMessagePos);
						//if passing a deferred object, reject it. TODO: should we do this? technically it was a success, but we are treating as a failure
						if (dfd) {
							dfd.reject();
						}
					} else {
						if (suppressSuccessTrigger === false) {
							$(document).trigger('$$ash:ajaxSuccess');
						}

						if (dfd) {
							dfd.resolve();
						}

						if (typeof callback === 'function') {
							callback(response, textStatus, xhr, cbOpts);
						}

						if (typeof callback2 === 'function') {
							callback2(response, textStatus, xhr, cbOpts);
						}
					}
				},
				complete: function (xhr) {
					$(document).trigger('$$ash:ajaxComplete');

					if (submitButton) {
						submitButton.trigger('ajaxPostComplete');
					}
					if (obj) {
						obj.trigger('ajaxPostComplete');
					}
					if (loaderOpts) {
						$$ash.removeLoader();
					}
					if (!complete && !always) {
						getLocation();
					} else {
						if (typeof complete === 'function') {
							complete();
							getLocation();
						}
						if (typeof always === 'function') {
							always();
							getLocation();
						}
					}
					function getLocation() {
						xLocation = xhr.getResponseHeader('X-Location');
						if (xLocation) {
							location.href = xLocation;
						}
					}
				}
			};

			if (dataType) {
				ajaxOpts.dataType = dataType;
			}

			if (traditional) {
				ajaxOpts.traditional = traditional;
			}

			return $.ajax(ajaxOpts);
		}
	},
	connectedWidgetInit: function (json) {
		var obj = $('.connectedWidget');

		obj.removeClass('loaderInit');
		$$ash.removeLoader();

		$$ash.handlebarsInit(obj, {
			jsonData: $$ash.unstringJSON(json),
			callback: function () {

			}
		});
	},
	ashModalPrinter: function (logoImgs, content, cssLinks) {
		//open new window
		var newWindow = window.open('', '', 'width=800,height=600');
		//set DOCTYPE, open html and head
		newWindow.document.write('<!DOCTYPE html><html><head>');
		//add stylesheet(s) from parent's html
		cssLinks.each(function () {
			newWindow.document.write('<link rel="stylesheet" href="' + $(this).attr('href') + '">');
		});

		//add a script to the popup window to print when the document is done loading
		newWindow.document.write('<script>' + 'window.onload = function() {window.print();window.close();}' + '</script>');
		//close the head and open the body
		newWindow.document.write('</head><body>');
		//add logos from parent's html if they are passed to print function
		if (logoImgs) {
			logoImgs.each(function () {
				newWindow.document.write('<div class="logo-cont"><img src="' + $(this).attr('src') + '" /></div>');
			});
		}
		//add content and closing html
		newWindow.document.write(content + '</body></html>');
		//close document
		newWindow.document.close();

	},
	addLoader: function (obj, loaderPos, align, containerClass) {  //add handlebars loader
		$$ash.removeLoader();

		var loaderHtml = '<svg class="progressLoader" width="40" height="40"><circle cx="20" cy="20" r="15" stroke-dasharray="66 100"></svg>';
		var loaderSel;
		var loader;

		containerClass = containerClass || 'svg-loader';

		//if obj is already a JQ object, keep as is
		if (!(obj instanceof jQuery)) {
			obj = $('.' + obj);
		} else {

		}

		loaderSel = $('.' + containerClass);

		if (!loaderSel.length) {
			if (!align) {  //add alignment of loader, if avail
				align = '';
			}
			loader = '<div class="' + containerClass + ' ' + align + '">' + loaderHtml + '</div>';

			switch (loaderPos) {  //position loader in relation to select obj
				case 'replace':
					obj.html(loader);
					break;
				case 'after':
					obj.after(loader);
					break;
				case 'prepend':
					obj.prepend(loader);
					break;
				case 'append':
					obj.append(loader);
					break;
				case 'before':
					obj.before(loader);
					break;
				default:
					obj.before(loader);
			}
		}
	},
	removeLoader: function (loaderClass, context) {
		var loaderSel;
		context = context || document;

		if (loaderClass) {  //if class not specified, use 'loader'
			loaderSel = $('.' + loaderClass);
		} else {
			loaderSel = $('.loader').add('.svg-loader');
		}

		$(loaderSel, context).remove();
	},
	preventDefaultFormSubmit: function () { //all forms with class ajaxPost will have their default submit prevented
		$('form.ajaxPost').on('submit.prevent', function (e) {
			e.preventDefault();
		});
	},
	//AO: Writing formSubmit2 to eventually replace original form submit.
	//Improved modularity and flexibility, stripping out validation stuff
	ajaxFormSubmit: function (obj, opts) {
		var url = opts.url || obj.attr('action'),
			type = opts.type || 'POST',
			data = opts.data,
			dfd = opts.dfd,
			beforeSend = opts.beforeSend, //function you want to run before the ajax request
			callback = opts.callback,
			callback2 = opts.callback2,
			cbOpts = opts.cbOpts,
			success = opts.success,
			always = opts.always,
			failMessage = opts.failMessage,
			failMessageLocation = opts.failMessageLocation || obj,
			loaderOpts = opts.loaderOpts,
			loaderLocation = loaderOpts && loaderOpts.location,
			loaderPosition = loaderOpts && loaderOpts.position || 'after',
			loaderAlign = loaderOpts && loaderOpts.align || 'center',
			always = opts.always,
			submitButton,
			disableSubmitWhilePostingOpts = opts.disableSubmitWhilePostingOpts; //Pass in an object w/ options if you want to disable submit button while POSTing

		if (!opts) {
			try {
				console.log('AJAX POST options not defined. Attempting to post with default options');
				data = obj.serialize();
			} catch (e) {
				alert('Error serializing form data. Please contact us with error details. ' + e.name + ': ' + e.message);
				throw 'Error: Cannot serialize form data!' + e.name + ': ' + e.message;
			}
		}

		if (loaderOpts && !loaderLocation) {
			throw new Error('You must specify where to insert the loader');
		}
		$$ash.ajax({
			obj: obj,
			method: type,
			url: url,
			data: data,
			failMessageLocation: obj,
			beforeSend: beforeSend,
			callback: callback,
			callback2: callback2,
			always: always,
			cbOpts: cbOpts
		});
	},
	metaNavInit: function (theTriggers, theNavList) {
		$(theTriggers).on('click', function (e) {
			e.preventDefault();
			if (!Modernizr.mq('screen and (min-width: 768px)')) {
				$(theNavList).toggleClass('active');
				$(theTriggers).toggleClass('isOpen');
			}
		});
		$(theNavList).on('focusin', function () {
			$(theNavList).addClass('active');
		});
		$('.last-item, .dropdown-menu-trigger').on('blur', function () {
			$(theNavList).removeClass('active');
		});
	},
	viewMore: function (opts) {
		var obj = opts.obj,
			jsonData = $$ash.unstringJSON(opts.jsonData),
			keyToSlice = opts.keyToSlice || obj.attr('data-key-to-slice'),
			numberToSlice = opts.numberToSlice || parseInt(obj.attr('data-number-to-slice')) || 10,
			callback = opts.callback,
			startSliceIndex = opts.startSliceIndex,
			target = opts.targetId || obj.attr('data-ash-target-id'),
			insertType = opts.insertType || obj.attr('data-insert-type') || 'html',
			unslicedArray,
			arrayLength;

		if (!target) {
			throw ('You MUST supply a targetId!');
		}

		obj.addClass('clicked');

		function showResults(templateData) {
			var newStartIndex,
				callback2 = function (startIndex, totalLength) {
					if (startIndex >= totalLength) {
						obj.remove();
					}

					if (typeof callback === 'function') {
						callback();
					}
				};

			function jsonSlicer(startIndex) {
				var endSlice = parseInt(startIndex) + parseInt(numberToSlice);
				insertType = 'append';

				jsonData[keyToSlice] = unslicedArray && unslicedArray.slice(startIndex, endSlice);

				newStartIndex = endSlice;
			}

			function initRender(jsonData) {
				unslicedArray = keyToSlice && jsonData[keyToSlice];
				arrayLength = unslicedArray && unslicedArray.length;

				if (keyToSlice) {
					jsonSlicer(startSliceIndex);

					obj.on('click.viewMoreSliced', function () {
						jsonSlicer(newStartIndex);

						$$ash.renderTemplate(obj, templateData, jsonData, target, function () {
							callback2(newStartIndex, arrayLength);
						}, { insertType: insertType });
					});
				} else {
					newStartIndex = arrayLength || 0; //remove view more button if inserting everything
				}

				$$ash.renderTemplate(obj, templateData, jsonData, target, function () {
					callback2(newStartIndex, arrayLength);
				}, { insertType: insertType });
			}

			if (jsonData) {
				initRender(jsonData);
			} else {
				$$ash.getTemplateJson(obj).done(function (response) {
					jsonData = $$ash.unstringJSON(response);

					initRender(jsonData);
				});
			}
		}

		if (obj.cachedTemplate) {
			showResults(obj.cachedTemplate);
		} else {
			$$ash.getHandlebarsTemplate(obj).done(function (response) {
				obj.cachedTemplate = response;

				showResults(obj.cachedTemplate);
			});
		}
	},
	chatterPost: function (response, textStatus, xhr, cbOpts) {
		var ele = cbOpts.ele,
			jsonData = $$ash.unstringJSON(response),
			templateHtml;

		$.when(
			$.get(ele.attr('data-ash-template'), function (data) {
				templateHtml = data;
			})

		).then(
			//success
			function () {
				if (jsonData.error) {
					$$ash.ajaxFail($('.comment-cont'), jsonData.errorMessage);
				} else {
					$$ash.renderTemplate(ele, templateHtml, jsonData, ele.attr('data-ashcontentfill'), function () {
						if (typeof deleteCommentBind !== 'undefined') {
							setupAshModal($('.removeComment'));
							deleteCommentBind();
						}
					});
					ele.find('input, textarea').not('input[type=submit]').val('');
				}
			},
			//fail
			function (xhr) {
				$$ash.ajaxFail($('.comment-cont'), undefined, xhr);
			}
		);
	},
	prepopulateDate: function (obj, date) {
		//boolean flag set to true if the form has class 'validateDate'
		var validateDate = $(obj).closest('form').hasClass('validateDate'),
			//boolean flag set to true if the input type is 'date'
			isTypeDate = obj.type === 'date',
			//the name that ties this element to the matched element
			match = obj.getAttribute('data-ash-match'),
			//the startDate input
			startInput = $u('.startDate[data-ash-match=' + match + ']'),
			//the endDate input
			endInput = $u('.endDate[data-ash-match=' + match + ']'),
			//startDate value to be used in the convertDate function
			startDate = (date) ? date : startInput[0].value,
			endDate = '';
		//if the input does NOT have a 'date' inputtype
		if (!isTypeDate) {
			//get endDate in ISO format only if date is being validated - else get date in standard format from convertDate function
			endDate = (validateDate) ? $$ash.convertDate({ str: startDate, opDay: +1 }) : $$ash.convertDate({ str: startDate, opDay: +1, iso: true });
			//populate the jQuery UI Datepicker fallback
			if (!endInput[0].value) {
				//must trigger change event for ashVal
				$(endInput).datepicker('setDate', endDate).change();
				//set hidden altField
				$$ash.setHiddenInput(endInput[0]);
			}
			//else if the input DOES have a 'date' inputtype
		} else {
			//get endDate in ISO format from convertDate function
			endDate = $$ash.convertDate({ str: startDate, opDay: +1, iso: true });
			//populate the native date input
			if (!endInput[0].value) {
				//must trigger change event for ashVal
				$(endInput).val(endDate).change();
			}
		}
	},
	//jQuery datepicker
	//default format: mm/dd/yy  (2-digit month, 2-digit day, 4-digit year)

	//HTML5 date input type
	//format: yyyy-mm-dd

	//str = "2015-06-09T15:20:00-07:00"; //assumes local time zone
	//str = "2015-06-09"; //assumes UTC time zone
	convertDate: function (opts) {
		//date string to be operated on
		var str = opts.str,
			//operator and value for month calculation
			opMonth = opts.opMonth || null,
			//operator and value for day calculation
			opDay = opts.opDay || null,
			//operator and value for year calculation
			opYear = opts.opYear || null,
			//flag to set output format
			iso = opts.iso || false,
			//array for split date strings
			strArr = [],
			//date string to create date object
			dateStr,
			//date object for calculating new date
			dateObj1,
			//date object for compiling and formatting new date
			dateObj2,
			month,
			day,
			year,
			outputDate;

		//if str is in the ISO "date-only" format
		if (str.indexOf('-') === 4 && str.length === 10) {
			//split the str
			strArr = str.split('-');
			//set to: mm/dd/yyyy format so that new Date() parses it as local time
			dateStr = strArr[1] + '/' + strArr[2] + '/' + strArr[0];
		} else {
			dateStr = str;
		}
		//calculate the new date
		dateObj1 = new Date(dateStr);
		month = (dateObj1.getMonth() + 1) + opMonth;
		day = dateObj1.getDate() + opDay;
		year = dateObj1.getFullYear() + opYear;
		//compile and format the new date
		dateObj2 = new Date(year, month - 1, day);
		month = ("0" + (dateObj2.getMonth() + 1)).slice(-2);
		day = ("0" + (dateObj2.getDate())).slice(-2);
		year = dateObj2.getFullYear();

		//construct output date based on iso flag
		outputDate = (iso) ? year + '-' + month + '-' + day : month + '/' + day + '/' + year;
		return outputDate;
	},
	setHiddenInput: function (obj) {
		var value = $$ash.convertDate({ str: obj.value, iso: true }),
			target = obj.getAttribute('data-ash-target-id');

		//if hidden input is not present, return out of function
		if (!target) { return false; }

		$u('#' + target)[0].value = value;
	},
	formatter: function (str, type) {
		var string = str.toString(),
			newString;

		switch (type) {
			case 'phone':
				//123-456-7890
				newString = string.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
				break;
		}

		if (newString) {
			return newString;
		} else {
			//if newString is undefined,NaN, etc. just return the original text
			return str;
		}
	},
	scroller: function (arg) {
		var element = null;
		var ease = Power3.easeOut; //default setting
		var duration = 2; //default setting
		var offset = 0;

		if (arg && arg.constructor === Object) {
			element = arg.element || element;
			ease = arg.ease || ease;
			duration = arg.duration || duration;
			offset = arg.offset || offset;
		}

		if (element && element instanceof jQuery && element.length) {
			offset = element.offset().top;
		}

		if (TweenLite && TweenLite._plugins && typeof TweenLite._plugins.scrollTo === 'function') { //TODO? USE GSAP TO ANIMATE WINDOW
			TweenLite.to(window, duration, { scrollTo: { y: offset }, ease: ease });
		} else {
			$('html, body').animate({
				scrollTop: offset
			}, duration);
		}
	},
	storage: {
		//localStorage functions
		local: {
			//set a localStorage key/value pair
			set: function (key, val) {
				localStorage.setItem(key, val);
			},
			//get a localStorage value from a key
			get: function (key) {
				return localStorage.getItem(key);
			},
			//remove a localStorage value from a key
			remove: function (key) {
				localStorage.removeItem(key);
			}
		},
		//sessionStorage functions
		session: {
			//set a sessionStorage key/value pair
			set: function (key, val) {
				sessionStorage.setItem(key, val);
			},
			//get a sessionStorage value from a key
			get: function (key) {
				return sessionStorage.getItem(key);
			},
			//remove a sessionStorage value from a key
			remove: function (key) {
				sessionStorage.removeItem(key);
			}
		}
	},
	storeValues: function (obj, storeType) {
		var storeItmFlag = 'data-store-item';
		var storeTxtFlag = 'data-store-text';
		var storeValFlag = 'data-store-value';
		var storeItmObj = obj.find('[' + storeItmFlag + ']');

		//get text or input value and store it in a local/session variable
		function storeData(data, type, flag, baseKey) {
			var key, value, i;
			for (i = 0; i < data.length; i++) {
				key = baseKey + data[i].getAttribute(flag);
				value = (type === 'text') ? data[i].innerText : data[i].value;

				$$ash.storage[storeType].set(key, value);
			}
		}

		function removeData(data, flag, baseKey) {
			var key, i;
			for (i = 0; i < data.length; i++) {
				key = baseKey + data[i].getAttribute(flag);

				$$ash.storage[storeType].remove(key);
			}
		}

		//loop through storeItem flags
		for (var i = 0; i < storeItmObj.length; i++) {
			var item = storeItmObj[i];
			var baseKey = item.getAttribute(storeItmFlag);
			var txtData;
			var valData;

			//if flag is on a radio-container
			if ($(item).hasClass('radio-container')) {
				var name = item.querySelector('input').getAttribute('name');
				var input = item.querySelector('input[name="' + name + '"]');
				//find the checked radio
				var checked = item.querySelector('input[name="' + name + '"]:checked');
				var inputType = (checked) ? checked : input;
				var parent;

				//find the containing element
				parent = $(inputType).parent();
				//find any store text flags
				txtData = parent.find('[' + storeTxtFlag + ']');
				(checked) ? storeData(txtData, 'text', storeTxtFlag, baseKey) : removeData(txtData, storeTxtFlag, baseKey);
				//find any store value flags
				valData = parent.find('[' + storeValFlag + ']');
				(checked) ? storeData(valData, 'input', storeValFlag, baseKey) : removeData(txtData, storeTxtFlag, baseKey);
			} else {
				//find any store text flags
				txtData = item.querySelectorAll('[' + storeTxtFlag + ']');
				storeData(txtData, 'text', storeTxtFlag, baseKey);
				//find any store value flags
				valData = item.querySelectorAll('[' + storeValFlag + ']');
				storeData(valData, 'input', storeValFlag, baseKey);
			}

			//TODO: Add if statements to get storage data for other inputs
		};
	},
	displayValues: function (obj, storeType) {
		var dispItmFlag = 'data-display-item';
		var dispItmObj = obj.find('[' + dispItmFlag + ']');
		var item, key, value, i;

		for (i = 0; i < dispItmObj.length; i++) {
			item = dispItmObj[i];
			key = item.getAttribute(dispItmFlag);
			value = $$ash.storage[storeType].get(key);

			if (value !== null) {
				item.innerText = value;
			}
		}
	},
	populateFields: function (fields, json, pop, dis) {
		//fields = obj containing all of the fields that are to be populated
		//json = json string containing values that will populate the intended fields (key names must match fields names)
		//pop = (boolean) populate the fields?
		//dis = (boolean) disable the fields?
		var data = $$ash.unstringJSON(json);

		fields.each(function () {
			var field = $(this),
				name = field.attr('name'),
				value = (pop) ? data[name] : '';

			if (field.is('select')) {
				field.select2('val', value).prop('disabled', dis);
			} else if ((field.is('[type=radio]') || field.is('[type=checkbox]')) && field.val() === value) {
				field.prop('checked', pop).prop('disabled', dis);
			} else {
				field.val(value).prop('disabled', dis);
			}
			$$ashVal.clearValidation(field);
		});
	},
	scrollThrottle: function (callback, timeDelay, events) {
		var scrollTimer;

		if (timeDelay == null) {
			timeDelay = 250;
		} else if (!timeDelay > 0) {
			throw new Error('timeDelay must be greather than 0');
		}

		events = events || 'scroll.ashThrottle';

		$(window).on(events, function () {
			//control timing of throttle
			if (!scrollTimer) {
				scrollTimer = setTimeout(function () {
					scrollTimer = null;
					callback();
				}, timeDelay);
			}
		});
	},
	debounce: function (callback, delay) {
		var timeout;

		return function (e) {
			function fn() {
				timeout = null;
				callback(e);
			};

			clearTimeout(timeout);
			timeout = setTimeout(fn, delay);
		};
	},
	inputChecker: function (input, options) {
		var opts = options || {};
		var parentForm = opts.form || input.closest('form');
		var event = opts.event || 'change';
		var customChecker = opts.customChecker || false;
		var url = opts.url || input.attr('data-ajax-url');
		var beforeSend = opts.beforeSend;
		var complete = opts.complete;
		var callback = opts.callback;
		var optionalData = opts.optionalData;

		if (url == null || (!customChecker && typeof callback !== 'function')) {
			throw new Error('You must pass in an input field, an ajax URL AND a callback function!');
		}

		function handleChangeEvent(date) {
			var data;
			// if input type date is supported, use date in default ISO format, else convert to ISO
			var inputDate = Modernizr.inputtypes.date ? date : $$ash.convertDate({ str: date, iso: true });
			var defaultData = {
				value: date
			};

			if (typeof customChecker === 'function') {
				if (customChecker() === true) {
					callback();
				}
			} else {
				// Add data other than just the input value to the network request if it is passed in
				if (optionalData) {
					data = $.extend({}, defaultData, optionalData)
				} else {
					data = defaultData;
				}

				// Remove any previous error messages
				if (document.querySelector('.error') !== null) {
					document.querySelector('.error').parentNode.removeChild(document.querySelector('.error'));
				}

				$$ash.ajax({
					method: 'POST',
					data: data,
					url: url,
					failMessageLocation: parentForm,
					beforeSend: function () {
						$$ash.removeButtonOnSubmit(parentForm);

						if (typeof beforeSend === 'function') {
							beforeSend();
						}
					},
					callback: callback,
					complete: function () {
						parentForm.trigger('ajaxPostComplete');

						if (typeof complete === 'function') {
							complete();
						}
					}
				});
			}
		}

		// if input type date is supported, use change event, else use onClose event on jQuery datepicker
		if (Modernizr.inputtypes.date) {
			parentForm.on(event, input.selector, function () {
				var date = input.val();
				handleChangeEvent(date);
			});
		} else if (typeof $.datepicker !== 'undefined') {
			// override onClose event to trigger handleChangeEvent function and set hidden input
			input.datepicker('option', 'onClose', function (date) {
				handleChangeEvent(date);
				$$ash.setHiddenInput(this);
			});
		}
	},
	removeSelectEmptyOption: function () {
		var selectObj = $('select');
		var placeholderData;
		var firstOption;
		var doNotClear;

		function removeEmptyOptionTag(obj) {
			if (obj.is(':empty')) {
				obj.remove();
			}
		}

		if (selectObj.length) {
			selectObj.each(function () {
				firstOption = $(this).find('option').first();
				placeholderData = $(this).data('select2-placeholder') ? $(this).data('select2-placeholder') : $(this).attr('placeholder');
				removeEmptyIgnore = $(this).data('remove-empty-ignore') === true;

				//check if this select needs to be ignored if so make the first option the same as the paceholder and set it to disabled
				//this is used for mobile view since select2 isnt available and we needed placeholder for contact us provider field
				if (removeEmptyIgnore) {
					firstOption[0].text = placeholderData;
					firstOption[0].disabled = true;
					//check if placeholder is all whitespace with regex; if not remove first option
				} else if (!(/^\s+$/.test(placeholderData))) {
					removeEmptyOptionTag(firstOption);
				}
			});
		}
	},
	initializeDonutChart: function (element) {
		//added var to check for JSON url before doing ajax call
		var jsonUrl = element.getAttribute('data-json-url');

		if (jsonUrl) {
			$$ash.ajax({
				url: element.getAttribute('data-json-url')
			}).done(function (response) {
				var donut = new DonutChart({
					selector: element,
					data: response
				});

				donut.initialize();
			});
		}
	},
	// target = element for modal handlebars template to appear in
	// trigger = element to trigger modal
	// data = data to load in modal
	openModalFromTemplate: function (options) {
		var target = options.target;
		var trigger = options.trigger;
		var data = options.data;
		var closeCallback = options.closeCallback || null;
		var openCallback = options.openCallback || null;

		// Render template that contains the markup for the modal onto the page
		$$ash.handlebarsInit(trigger, {
			target: target,
			templateUrl: target.attr('data-modal-template-url'),
			jsonData: data,
			callback: function (obj, response) {
				trigger.off('click.createModal');

				// Create modal out of rendered template
				setupAshModal(trigger, function () {
					trigger.off('click.ashModal');
					trigger.trigger('click.createModal');

					// If close callback is a function, run it
					if (typeof (closeCallback) === 'function') {
						closeCallback(obj, response);
					}
				});

				trigger.trigger('click.ashModal');

				// If open callback is a function, run it
				if (typeof (openCallback) === 'function') {
					openCallback(obj, response);
				}
			}
		});
	},
	setInterval: function (clear, options) {
		var id = options.id || 0;
		var seconds = options.seconds;
		var counter = options.counter || null;
		var callback = options.callback;
		var intervalId;
		var storedId = $$ash.storage.session.get('interval' + id);

		if (clear) {
			window.clearInterval(storedId);
		} else {
			//set interval id when interval is set
			intervalId = window.setInterval(function () {
				//store interval id with key that contains timeout id for later reference
				$$ash.storage.session.set('interval' + id, intervalId);
				seconds--;

				if (seconds === 0 || seconds > 1) {
					if (counter) {
						counter.textContent = seconds + ' seconds';
					}
				}
				if (seconds === 1) {
					if (counter) {
						counter.textContent = seconds + ' second';
					}
				}
				if (seconds === 0) {
					window.clearInterval(intervalId);
					intervalId = undefined;
					if (typeof callback === 'function') {
						callback();
					}
				}
			}, 1000);
		}
	},
	sessionTimerInit: function (options) {
		var isPublic = document.querySelector('.isPublic');
		var modalTrigger = document.querySelector('.sessionModal');
		var keepTrigger = document.querySelector('.keepSession');
		var keepUrl = null;
		var endTrigger = document.querySelector('.endSession');
		var endUrl = null;
		var session = {
			delayMins: options.delayMins,
			calculateDelay: function () {
				return this.delayMins * 60 * 1000;
			},
			setTimeout: function () {
				session.timeoutId = window.setTimeout(function () {
					timer.countStart();
				}, session.calculateDelay());
			},
			keepSession: function () {
				$$ash.ajax({
					url: keepUrl,
					method: 'POST'
				}).fail(function () {
					//end the session
					session.endSession();
				}).done(function (data) {
					//if there is a server error
					if (data.error) {
						//end the session
						session.endSession();
					} else {
						//clear old session
						window.clearTimeout(session.timeoutId);
						$$ash.setInterval(true, { id: session.timeoutId });
						//restart the session timer
						session.setTimeout();
					}
				});
			},
			endSession: function () {
				//log user out and end the session
				if (typeof options.callback === 'function') {
					options.callback();
				} else {
					window.location.pathname = endUrl;
				}
			}
		}
		var timer = {
			countSecs: options.countSecs,
			countContainer: document.querySelector('.timerCount'),
			countStart: function () {
				this.countContainer.textContent = this.countSecs + ' seconds';
				//display modal
				$$ash.runModal($(modalTrigger), 'click');

				//start countdown timer
				$$ash.setInterval(false, {
					id: session.timeoutId,
					seconds: this.countSecs,
					counter: this.countContainer,
					callback: session.endSession
				});
			}
		}

		if (!isPublic && modalTrigger) {
			keepUrl = modalTrigger.getAttribute('data-ash-keepsession-url');
			endUrl = modalTrigger.getAttribute('data-ash-endsession-url');
			//when user clicks log out button to end session
			keepTrigger.addEventListener('click', function (e) {
				e.preventDefault();
				var modalClose = $(this).closest('.ashModalContent').find('.ashModalClose');

				//keep session
				session.keepSession();

				//close modal
				$(modalClose).trigger('click');
			});

			//when user clicks continue button to keep session
			endTrigger.addEventListener('click', function (e) {
				e.preventDefault();
				var modalClose = $(this).closest('.ashModalContent').find('.ashModalClose');

				//end session
				session.endSession();

				//close modal
				$(modalClose).trigger('click');
			});

			//start session timer
			session.setTimeout();
		}
	},
	enableOnCheck: function () {
		//array of all buttons that can be enabled
		var buttonsToEnable = $('.enableOnCheck');
		var thisButton;
		var containerSelector;
		var container;
		var checkboxes;

		function allChecked() {
			var checked = checkboxes.filter(':checked');
			return checkboxes.length === checked.length;
		}

		function toggleButton() {
			thisButton.prop('disabled', !allChecked());
		}

		if (buttonsToEnable.length) {
			buttonsToEnable.each(function () {
				thisButton = $(this);
				//container selector will be .btn-cont by default
				containerSelector = thisButton.attr('data-ash-enable-container') || '.btn-cont';
				container = thisButton.closest(containerSelector);
				checkboxes = container.find('input[type="checkbox"]');

				toggleButton();

				checkboxes.on('click', function () {
					toggleButton();
				});
			});
		}
	}
};

$$ash.ajaxGet = $$ash.ajax; //temporary patch to make any calls for ajaxGet do a call to $$ash.ajax.
