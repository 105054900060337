; if (!$$hyrTypekit) {
    var $$hyrTypekit = 'ifb8rzb';
}

if (!$$ashRedirect) {
    var $$ashRedirect = '/Redirect/External/';
}

$$ash.getTypeKit($$hyrTypekit); //typekit id passed to async call to typekit script

var $$siteSettings = {
    name: 'Healthyroads',
    url: 'https://www.healthyroads.com',
    fbAppId: '904388559611726',
    fbNamespace: 'healthyroads'
};

(function ($$ashChartOpts, $, undefined) { //ASHCHART OPTIONS
    var donut, chart, arc, tempOpts,
        s = { //default values
            fontFam: "'brandon-grotesque', 'sans-serif'",
            fontColor: '#ffffff',
            fillColor: '#0376bd',
            secondaryFillColor: '#72cdc4', //in this case, self-measured steps
            bgColor: '#f1f1f1',
            capitalize: true
        },
        opts = [
            $$ashChartOpts.donut = $.extend({}, s),
            $$ashChartOpts.chart = $.extend({}, s),
            $$ashChartOpts.arc = $.extend({}, s)
        ];

    //option to override default settings above
    donut = {
        gap: true,
        fontColor: s.fillColor,
        secondaryTextSize: 0.75
    };
    chart = {
        fontColor: '#000'
    };
    arc = {
        bgColor: '#D6D6D6'
    };
    //end override options

    tempOpts = [donut, chart, arc];

    for (var i in opts) {
        if (opts.hasOwnProperty(i)) {
            $.extend(opts[i], tempOpts[i]);
        }
    }
}(window.$$ashChartOpts = window.$$ashChartOpts || {}, jQuery));

function hyrHdrInit() {
    var dskHeader = $('.top-header > .row'),
        dskLogo = dskHeader.find('.logo-cont'),
        mblHeader = $('.mobile-header'),
        contClass = '.btn-cont',
        dskLogReg = dskHeader.find(contClass),
        dskLogRegCont = dskLogReg.closest('[class^="col-"]'),
        mblLogReg = mblHeader.find(contClass),
        mblLogRegCont = mblLogReg.closest('[class^="col-"]');
    
    function createWrapper(classes) {
        return '<div class="' + classes + '"><div class="row clearfix"></div></div>';
    }

    //functions for when login/register buttons are in desktop header
    if (Modernizr.mq('screen and (max-width: 767px)')) {
        var tempDskLogReg = $(dskLogReg).detach(),
            columnClass = 'col-12',
            wrapper = createWrapper(columnClass);
        $(tempDskLogReg).appendTo(mblHeader).wrap(wrapper); //put login/register buttons in mobile header
        $(dskLogRegCont).remove();
    }
    //functions for when login/register buttons are in mobile header
    if (Modernizr.mq('screen and (min-width: 768px)')) {
        var tmpMblLogReg = $(mblLogReg).detach(),
            columnClass = 'col-6',
            wrapper = createWrapper(columnClass);
        $(tmpMblLogReg).insertAfter(dskLogo).wrap(wrapper); //put login/register buttons back in desktop header
        $(mblLogRegCont).remove();
    }
}

//placeholder fix for older browsers
$(document).ready(function () {

    // If not a touch device and select2 library exist
    // REASON: For hyrLite bundle, select2 isn't included. If select2 library doesn't exist, don't run this selectAnimateInit IIFE that updates select HTML element with select2 function. 
    if (!Modernizr.touchevents && window.Select2) {
        (function selectAnimateInit() {
            var selectObj = $('select'),
                placeholderData = null,
                mainContent = $('.main-content, .main'); //TODO: delete .main when we transition to new template markup

            if (selectObj.length) {
                selectObj.each(function () {
                    placeholderData = $(this).data('select2-placeholder');
                    $(this).select2({
                        placeholder: placeholderData,
                        minimumResultsForSearch: -1,
                        formatNoMatches: function (term) {
                        	return 'Loading...';
                        }
                    });
                });
            }

            mainContent.on('select2-open', $('select'), function () {
                $('.select2-drop').addClass('animate-open'); //can't cache this... select2 creates it on the fly!
            });

            mainContent.on('select2-close', $('select'), function () {
                $('.select2-drop').removeClass('animate-open'); //can't cache this... select2 creates it on the fly
            });
        })();
    } else {
        $$ash.removeSelectEmptyOption();
    }

    //init base offcanvas nav
    offCanvasNavInit();

    //metaNav for mobile, shows and hides user info on click user-info and info-name
    $$ash.metaNavInit('header .user-info .user-info-name, header .user-info .user-info-avatar-cont, header .user-info a.dropdown-menu-trigger', 'header .user-info .dropdown-menu-cont');

    (function metaNavNoMessage() {
        var obj = $('.user-info'),
            iconemail = obj.find('.icon-email'),
            countericon = obj.find('.counterIcon');
        if (obj.length) {
            if (!countericon.length) {
                iconemail.addClass('noMessage');
            }
        }
    })();



    function disableDefaultOption(dOpt) { //disable options in select2 dropdowns
        dOpt.on('click', function () {
            $(dOpt.find('option')).each(function () {
                var opt = $(this);
                if (opt.val() === '') {
                    opt.prop('disabled', true);
                }
            });
            dOpt.off('click'); //kill disabling function once the option is used
        });
    }
    $('.ashFilterTrigger').each(function () {
        var obj = $(this);

        if (!obj.hasClass('label_group')) { //fixing conflict with challenge ashFilterTrigger
            obj.ashFilter({
                event: 'change',
                trigger: $(this),
                filterItem: $('.ashFilter'),
                addSelected: 'selected',
                fadeInDur: 1,
                fadeOutDur: 0
            });

            //setup disable option
            disableDefaultOption(obj);
        }
    });

    function selectPopulateDropdown(elemID, select2) { //populate a related dropdown
    	var trigger = $('.select2.ashFilterTrigger'),
			elemOpt = $(elemID + ' option'),
			inquiryOpt = elemOpt.detach(), //remove and store all options in 2nd select
			elemEvent = (select2) ? 'select2-close' : 'change',
			elemVal = '',
			newOpt;

    	function buildOpts(obj, newOpt) {
    		//Remove all 2nd selects options
    		elemOpt.remove();

    		//loop thru each option and append those whose filter = its tag
    		obj.each(function () {
    			var selObj = $(this);
    			if (selObj.data('ash-filter') === undefined || selObj.data('ash-filter').indexOf(newOpt) > -1) {
                    selObj.appendTo(elemID);
                }
                // unselect option for select2 to prevent inquiry type from selecting the last option appended
                selObj.prop('selected', false);
            });
    	}

    	if (trigger.length !== 0) {
    		//disable the inquiry type dropdown
    		$(elemID).prop('disabled', true);

    		trigger.on(elemEvent, function () {
    			elemVal = (select2) ? $(this).select2('val') : $(this).val();
                newOpt = $('select.ashFilterTrigger option[value="' + elemVal + '"]').data('ash-filter-trigger');  //get filter tag

    			//enable the inquiry type dropdown
                $(elemID).prop('disabled', false);
                
    			//run build options function
                buildOpts(inquiryOpt, newOpt);
    
    			//setup disable option
    			disableDefaultOption($(elemID));
    		});
    	} else {
    		newOpt = $(elemID).data('ash-filter-default');
    		//run build options function
    		buildOpts(inquiryOpt, newOpt);
    	}
    }
    if (!Modernizr.touchevents) {
    	selectPopulateDropdown('#inquiryType', true); //select2 is supported
    } else {
    	selectPopulateDropdown('#inquiryType', false); //select2 is NOT supported
    	$$ash.removeSelectEmptyOption();
    }
    
    // Initialize navigation using ash-header
	navigation();

    $('.submitInfoTrigger').each(function () {
        var ashTrig = $(this);

        ashTrig.ashFilter({
            event: 'click',
            trigger: $(this),
            filterItem: $('.ashFilter'),
            callback: removeTrigger,
            fadeInDur: 1,
            fadeOutDur: 0
        });
        function removeTrigger() {
            ashTrig.hide();
        }
    });
    $('.readMoreTrigger').each(function () {
        var ashTrig = $(this);
        ashTrig.on('click', function (e) {
            e.preventDefault();
        });
        ashTrig.ashFilter({
            event: 'click',
            trigger: ashTrig,
            filterItem: $('.ashFilter'),
            fadeInDur: 1,
            fadeOutDur: 0
        });
    });
    $('.tabsFilter').each(function () {
        var ashTrig = $(this);
        ashTrig.ashFilter({
            event: 'click',
            trigger: $(this).find('li > a'),
            filterItem: $('.ashFilter'),
            fadeInDur: 1,
            fadeOutDur: 0
        });
    });

    (function modalSetup() {
        var obj = $('.modalOpen');

        setupAshModal(obj);

        obj.each(function () {
            obj = $(this);

            obj.on('click.ashModal', function () {

                if ($('.ashModal video').length) {
                    $('.ashModal video')[0].play();
                }
            });
        });
    })();

    if ($('.flexslider').length) {
        //DASHBOARD CAROUSEL/SLIDER
        if ($('.slideshow').length) {
            (function landingPageSliderInit() {
                var slideshow = $('.slideshow'),
                    newsSlide = $('.news-slide');

                slideshow.flexslider({
                    selector: '.slides > figure',
                    animation: 'slide',
                    controlNav: slideshow.find('figure').length < 2 ? false : true,
                    directionNav: slideshow.find('figure').length < 2 ? false : true,
                    touch: slideshow.find('figure').length < 2 ? false : true,
                    prevText: '<span class="icon-ArrowLeft"></span>',
                    nextText: '<span class="icon-ArrowRight"></span>',
                    start: function (slider) {
                        function newsSlideEqualHeight() {
                            var setHeight;

                            if (Modernizr.mq($$ash.mq('sm', 'min'))) {
                                setHeight = slider.height();
                            } else {
                                setHeight = 350;
                            }
                            newsSlide.find('.content-container').outerHeight(setHeight);
                        }

                        slider.addClass('loaded');

                        newsSlideEqualHeight();

                        $(window).on('resize', function () {
                            newsSlideEqualHeight();
                        });

                        newsSlide.flexslider({
                            animation: 'slide',
                            direction: 'vertical',
                            controlNav: false,
                            prevText: '<span class="icon-rotateUp"></span>',
                            nextText: '<span class="icon-rotateDown"></span>',
                            slideshow: false,
                        });
                    }
                });
            })();
        }

        //HOMEPAGE js
        if ($('.homepageSlider').length) {
            (function HomepPageInit() {

                function slideshowInit() {
                    var mainSlider = $('.homepageSlider > .flexslider');

                    mainSlider.flexslider({
                        selector: '.slides > figure',
                        animation: Modernizr.touchevents ? 'slide' : 'fade',
                        slideshowSpeed: 5000,
                        directionNav: true,
                        prevText: '<span class="icon-ArrowLeft"></span>',
                        nextText: '<span class="icon-ArrowRight"></span>',
                        controlsContainer: ".sliderOverlay > .container",
                        manualControls: '.sliderControls > ol > li',
                        start: function (slider) {
                        }
                    });
                }

                function homepageAshFilter() {
                    var obj = $('.hyrInfo > nav'),
                        filterItems = $('.ashFilter');

                    obj.ashFilter({
                        event: 'click',
                        trigger: obj.find('a'),
                        filterItem: filterItems,
                        callback: function () {

                        },
                        addSelected: 'active',
                        fadeInDur: 300,
                        fadeOutDur: 0,
                        preventDefault: true
                    });
                }

                function jumpDownInit() {
                    var arrow = $('.jumpArrow a'),
                        beGreat = $('.intro');

                    arrow.on('click.jumpDown', function (e) {

                        $('html, body').animate({
                            scrollTop: beGreat.offset().top
                        }, 750);

                        e.preventDefault();
                    });
                }

                slideshowInit();
                homepageAshFilter();
                jumpDownInit();
            })();
        }
    }

    //SELECT/DESELECT PROGRESS INDICATION
    if ($('.fs-facility-detail').length > 0) {
        $('.fs-facility-detail label.button').progressIndicator({
            'msg': 'Facility Selected',
            'buttonTrigger': true,
            'buttonClass1': 'select',
            'buttonText1': 'Select',
            'buttonClass2': 'deselect',
            'buttonText2': 'Deselect',
            'limitSelection': false,
            'chgTxt': 'span',
            'fadeInTime': 300,
            'fadeOutTime': 300,
            'DelayTime': 1000
        });
    }

    if ($('.poll').length) {
        var poll = $('.poll'),
            pollResults = $('.poll-results'),
            pollQues = $('.poll-questions'),
            pollBtnCont = poll.find('.btn-cont');
        function showQues(){
            pollResults.hide();
            pollQues.show();
            $$ash.flexheightFallback();
        }
        function showResults(){
            pollResults.show();
            pollQues.hide();
            $$ash.flexheightFallback();
        }
        function submitPoll(response, textStatus, xhr, cbOpts) {
            var ele = cbOpts.ele,
                jsonData = $$ash.unstringJSON(response);
            $.when(
                $.get(ele.attr('data-ash-template'), function (data) {
                    templateHtml = data;
                })

                ).then(
                //success
                    function () {
                        if (jsonData.error === true) {
                            $$ash.ajaxFail($('#poll-message'), jsonData.errorMessage);
                        } else {
                            $$ash.removeLoader();
                            $$ash.renderTemplate(ele, templateHtml, jsonData, ele.attr('data-ashcontentfill'));
                            ele.find('input, textarea').not('input[type=submit]').val('');
                            
                            pollQues.find('.poll-cont').html('<p class="notification">You\'ve already taken the poll</p>');
                            pollQues.find('input[type="submit"]').remove();
                            $('.view-ques').parent('.btn-cont').remove();
                            showResults();
                            $$ash.updateProgress();
                        }
                    },
                //fail
                    function (xhr) {
                        $$ash.ajaxFail($('#poll-list'), undefined, xhr);
                    }
                );
        }
        $('.view-results').on('click', function (e) {
            e.preventDefault();
            var ele = $(this);
            var jsonData,
                pollMsg = $('#poll-message');
            $.getJSON(ele.attr('data-json-url'), function (data) {
                jsonData = data;
            }).done(function () {
                $.get(ele.attr('data-ash-template-url'), function (data) {
                    templateHtml = data;
                }).done(function () {
                    if (jsonData.error === true) {
                        $$ash.ajaxFail(pollMsg, jsonData.errorMessage);
                    } else {
                        $$ash.renderTemplate(ele, templateHtml, jsonData, ele.attr('data-ash-target-id'), $$ash.flexheightFallback());
                        showResults();
                        $$ash.updateProgress();
                    }
                }).fail(function (xhr) {
                    $$ash.ajaxFail(pollMsg, jsonData.errorMessage, xhr);
                });
            }).fail(function (xhr) {
                $$ash.ajaxFail(pollMsg, 'JSON data error', xhr);
            });
        });
        $('.view-ques').on('click', function (e) {
            e.preventDefault();
            showQues();
        });
        showQues();
        poll.find('form').on('submit', function (e) {
            var form = $(this),
                ajaxData = form.returnCheckedInputsAsObject();

            e.preventDefault();

            $$ash.addLoader(pollBtnCont);
            pollBtnCont.remove();
            
            $$ash.ajaxFormSubmit(form, {
                data: ajaxData,
                cbOpts: { ele: form },
                callback: submitPoll
            }); //be sure to pass the form element along with a callback function
        });
        poll.find('.radio-container input').on('change', function () {
            poll.find('input[type="submit"]').prop('disabled', false);
        });
    }
    //hyrHdrInit(); //initialize HYR login/register buttons
    //view more content plugin show/hide
    $('.view-more-trigger').ashCollapsible({
        'obj': $('.view-more-trigger'),
        'panel': $('.view-more-body')
    });

    //PHA Disclosure - remove 'read more' button on click in old IE
    if (!(Modernizr.csstransitions)) {
        $('#PHADisclosureText').next().on('click', function () {
            $(this).siblings().addClass('active');
        });
    }
 
    $('#editorialBoard').ashTemplateInit({
        callback: function (trigr, jsonData, target) {
            var modalLinks = target.find('a');

            modalLinks.each(function(i) {
                var obj = $(this),
                    member = jsonData.EditorialStaffMemberList[i],
                    headerString = member.Header,
                    contentString = member.Content,
                    markup = $('<div />').html('<div><h2>' + headerString + '</h2>' + contentString + '</div>'); //TODO: Prettify this

                obj.ashModal({
                    theContent: markup,
                    closeContent: '<span class="icon-closeExit"></span>'
                });
            });
        }
    });

    var links = bodyLinkProcessing();
    runRedirect(links);
    runScrollTo(links);

}); //END DOCUMENT READY
$(window).on('load', function () {
    scrollIt(location.href); //scroll to a jump link location
});
$(window).on('load', function () {
    bulletinInit();
    $('.equal-heights > div').equalHeights();
    if ($('.invalid').length > 0) {
        $('.invalid:not(.select2-container)').each(function () {
            var obj = $(this);
            if (obj.is('select')) {
                obj.prev().nextUntil().andSelf().wrapAll('<span class="tooltip-wrapper"/>');
            } else {
                obj.next('span.tooltip-validation').andSelf().wrapAll('<span class="tooltip-wrapper"/>');
            }
        });
    }
    
}); //END WINDOW LOAD
