(function ($) {
	$.fn.ashModal = function (options) {
		var defaults = {
			'closeContent': 'Close', //anchor text unless closeBtnSrc is not null
			'closeBtnSrc': null, //source link used to add image to close
			'ovrContent': null, //overlay on top of content
			'callbackAfterClick': null, //callback executed after a click on the modal occurs
			'callbackAfterClose': null, //callback executed after the modal is closed
			'addModalClass': null //additional class that needs added to ashModalContent to override default CSS
		};

		var settings = $.extend({}, defaults, options);

		return this.each(function () {
			var obj = $(this);
			var o = settings;

			var format = o.format || obj.attr('data-ash-ashModalFormat') || 'image';
			var runOnce = obj.attr('data-ash-run-once') || 'false';
			//tag to remove close and escape options from modal
			var noClose = obj.attr('data-ash-no-close') || 'false';
			var closeContent = o.closeContent;
			var closeBtnSrc = o.closeBtnSrc;
			var ovrContent = $(o.ovrContent).html() || '';
			var callbackAfterClick = o.callbackAfterClick;
			var callbackAfterClose = o.callbackAfterClose;
			var addModalClass = o.addModalClass;
			//width of video
			var objWidth = obj.attr('data-ash-ashModalWidth') || '';
			//height of video
			var objHeight = obj.attr('data-ash-ashModalHeight') || '';
			//title of video
			var objTitle = obj.attr('title') || '';
			//href of embedded video
			var theHref = obj.attr('href');
			//source link of video player
			var theMediaPlayer = obj.attr('data-ash-mediaPlayer') || '';
			//source link of OGG video
			var theOgg = obj.attr('data-ash-oggFallback') || '';
			//source of flash video
			var theHrefFallback = obj.attr('data-ash-videoFallback') || '';
			//source url of json data
			var jsonUrl = obj.attr('data-ash-json-url');
			//source url of template
			var templateUrl = obj.attr('data-ash-template-url');
			//content to show up in modal
			var theContent;
			//for HTML content get the children from theContent
			var htmlContent;
			//flag to only build the modal once
			var buildModal = true;
			//flag to determine use of template
			var useTemplate = false;
			var target = obj.attr('data-ash-modal-target') ? $('#' + obj.attr('data-ash-modal-target')) : 'body';
			//If set, then any .loader elements will be removed and submit buttons will
			//  be shown to cleanup from previous display of the modal.
			var restoreButtons = obj.attr('data-ash-restore-buttons') ? true : false;
			//element used to contain the modal once the HTML has been built
			var modalElem;

			//change HTML based on type of modal
			function createModalContent() {
				switch (format) {
					case 'flash':
					    theContent = '<object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" width="' + objWidth + '" height="' + objHeight + '"><param name="wmode" value="opaque"><param name="allowfullscreen" value="true"><param name="allowscriptaccess" value="sameDomain"><param name="movie" value="' + theHref + '"><embed src="' + theHref + '" type="application/x-shockwave-flash" allowfullscreen="true" allowscriptaccess="sameDomain" width="' + objWidth + '" height="' + objHeight + '" wmode="opaque"></object>' + objTitle;
						break;
					case 'html5video':
						theContent = objTitle + '<video width="' + objWidth +'" controls autoplay><source src="' + theHref + '"  type="video/mp4" /></video>';
						break;
					case 'html':
						//if the modal content is coming from the page template
						if (o.theContent.children().length !== 0) {
							htmlContent = o.theContent.children();
							useTemplate = false;
						} else {
							htmlContent = '<div id="ashModalTemplate"></div>';
							useTemplate = true;
						}
						break;
					default:
						//image as default
						theContent = '<img src=' + theHref + '>';
				}
			}

			//markup for objTitle used for videos
			if (objTitle) {
				objTitle = '<h1 class="ashModalTitle">' + obj.attr('title') + '</h1>';
			}

			//markup for close button image
			if (closeBtnSrc) {
				closeContent = '<img src="' + o.closeBtnSrc + '" alt="Close Button">';
			}

			createModalContent(); //determine which content to create

			//Template DOM Elements and classes
			var closeBtn = noClose == 'true' ? '' : '<a class="ashModalClose" href="#">' + closeContent + '<span class="sr-only">Close modal window</span></a>', //if the no close tab is selected, clear out button
				contentCont = '<div class="ashModalPanel">' + theContent + '</div>',
				contentClass = addModalClass ? addModalClass + ' ashModalContent' : 'ashModalContent', //if addModalClass add it, otherwise class is ashModalContent
				modalContent = '<div class="' + contentClass + ' ashModalContent-' + format + '">' + closeBtn + contentCont + ovrContent + '</div>',
				container = 'ashModalCont',
				mask = '<div class="ashModalMask"></div>';

			obj.on('click.ashModal', function (e) {
				e.preventDefault();
				//if this is the first time through, build the modal body, else just show the saved modal
				if (buildModal) {
					buildModal = false;
					var modalBody = $('<section class="' + container + '">' + mask + '<div class="ashModalContentContainer"><div class="ashModal">' + modalContent + '</div></div></section>');
					//if HTML format clear HTML and append content
					if (format == 'html') {
						//if ashModalPanel is in the body and append to modalBody to avoid double ashModalPanel containers
						if ($(htmlContent).find('.ashModalPanel').length > 0) {
							modalBody.html(htmlContent.prepend(mask));
						}else{ //else append to the ashModalPanel frame
							modalBody.find('.ashModalPanel').html('').append(htmlContent);
						}
						//if HTML is in the base page, remove it
						if (o.theContent.length) {
							o.theContent.children().detach();
							o.theContent.remove();
						}
					}
					//append new modal to body
					modalBody.appendTo(target).fadeIn(250, function () {
						if (noClose == 'false'){  //if not no close, add close elements
							initClose(modalBody, '.ashModalClose, .ashModalExit');
						}
						if (format == 'html' && useTemplate) {
							$$ash.getTemplateData(obj, jsonUrl, templateUrl, 'ashModalTemplate', callbackAfterClick);
						}
					});
				} else {
					//check to see if the saved modalElem has content
					//if not, then insert the content before appending to the target
					if (modalElem.find('.ashModalPanel').html() === '') {
						modalElem.find('.ashModalPanel').html(htmlContent);
					}
					//If the option is set, restore any buttons that were replaced with loaders.
					if (restoreButtons) {
						modalElem.find('.loader').remove();
						modalElem.find('button[type="submit"]').show();
						modalElem.find('input[type="submit"]').show();
					}
					//append existing modal to body
					modalElem.appendTo(target).fadeIn(250);
				}
        //if redirect (links) is not defined
				var links = (typeof bodyLinkProcessing !== 'undefined') ? bodyLinkProcessing() : null;
				if (links) {
				    bodyLinkRedir(links);
				}

				//if modal open callback is set, run it
				if(typeof(callbackAfterClick) === 'function' && !useTemplate){
					callbackAfterClick(obj);
				}
				//if width is specified, change width
				if (objWidth) {
					$('.' + contentClass).css('width', objWidth);
				}
				$('html, body').addClass('hasModalOpen');
				
				if($('.ashModalContent').outerHeight(true) > $(window).height()) {
					function scrollbarWidth() {
					    var div = $('<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div>');
					    // Append our div, do our calculation and then remove it
					    $('.ashModal').append(div);
					    var w1 = $('div', div).innerWidth();
					    div.css('overflow-y', 'scroll');
					    var w2 = $('div', div).innerWidth();
					    $(div).remove();
					    return (w1 - w2);
					}
					$('.ashModalMask').css('width', 'calc(100% - ' + scrollbarWidth() + 'px)');
				}
			});

			function initClose(context, trigger) {
				$('body').on('keyup', function (e) {
					if (e.which === 27) {
						closeModal(context.find('.ashModal'));
					}
				});

				context.find('.ashModalContentContainer').on('click', function(e) {
					// check if the mouse target is not the body of the modal. Thus, its the ".ashModalMask" and close the modal.
					if (context.find('.ashModal').has(e.target).length === 0) {
						closeModal(context.find('.ashModal'));
					}
				});

				context.on('click.ashModal', trigger, function (e) {
					e.preventDefault();
					closeModal(e.target);
				});
			}

			function closeModal(closeEle) {
				var thisCont = $(closeEle).closest('.' + container),
					invalids = (window.$$ashVal ? (thisCont.find('.' + $$ashVal.failClass)) : null), //not every site uses ashval
					ajaxError = $(thisCont).find($('.error'));
				//close any select2s prior to closing the modal
				if ($('.ashModalContent select').length) {
					$('.ashModalContent select').select2('close');
				}
				thisCont.fadeOut(250, function () {
					//if modal close callback is set, run it
					if(typeof (callbackAfterClose) === 'function') {
						callbackAfterClose($(this));
					}
					if (!($(this).attr('data-ash-ashmodalkeep'))) {
						//save modal into variable
						modalElem = $(this).detach();
					}
					if (runOnce == 'true') {
						obj.off('click.ashModal');
                    }
                    if ($(".ashModal:visible").length === 0){
                        $('html, body').removeClass('hasModalOpen');
                    }
					//if ashVal exists and has an invalid item
					if (window.$$ashVal && invalids) {
						//run through each invalid and clear validation
						invalids.each(function (i, ele) {
							$$ashVal.clearValidation($(ele));
						});
					}
					//remove ajax errors when modal closes
					if (ajaxError.length) {
						ajaxError.remove();
					}
				});
			}
		});
	};
}(jQuery));